import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ReportProblem from "@material-ui/icons/ReportProblem";
import Send from "@material-ui/icons/Send";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Slide from "@material-ui/core/Slide";

import _ from "lodash";

import PlayDetailsTable from "./PlayDetailsTable";
import Loading from "./Loading";

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit * 2
  },
  icon: {
    margin: theme.spacing.unit
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`
  },
  iconGreen: {
    color: "green"
  },
  container: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    alignContent: "space-between"
  },
  containerItem: {
    margin: "auto",
    padding: `0 ${theme.spacing.unit * 2}px`
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class FeedRow extends Component {
  state = {
    feedOpen: false,
    playsOpen: false
  };

  _handleDialogOpen = () => {
    this.setState({ feedOpen: true });
    const { videoDiagnosticStore: store } = this.props.rootStore;
    const { gamePk, feedType } = this.props;
    store.fetchFeedDetails(gamePk, feedType);
  };

  _handlePlaysOpen = (gamePk, playIds) => {
    const { videoDiagnosticStore: store } = this.props.rootStore;
    store.selectedPlays = _.map(playIds, p => ({ playId: p, gamePk: gamePk }));
    this.setState({ playsOpen: true });
  };

  _handlePlaysClose = () => {
    const { videoDiagnosticStore: store } = this.props.rootStore;
    store.selectedPlays = [];
    this.setState({ playsOpen: false });
  };

  _handleDialogClose = () => {
    this.setState({ feedOpen: false });
    const { videoDiagnosticStore: store } = this.props.rootStore;
    store.selectedFeed = null;
  };

  _handleGameResend() {
    const { videoDiagnosticStore: store } = this.props.rootStore;
    const { feedDetails, gamePk } = this.props;
    store.resendGame(gamePk, feedDetails.feed);
  }

  render() {
    const { feedDetails, playCount, classes, gamePk } = this.props;
    const { videoDiagnosticStore: store } = this.props.rootStore;
    const selectedFeed = store.selectedFeed;
    const inError = !_.isEmpty(feedDetails.missing);

    return (
      <TableRow>
        <TableCell>
          {!inError ? (
            <CheckCircle className={classes.iconGreen} />
          ) : (
            <ReportProblem className={classes.column} color="error" />
          )}
        </TableCell>
        <TableCell>
          <Tooltip title="Show Feed Details">
            <Button className={classes.button} onClick={this._handleDialogOpen}>
              {feedDetails.feed}
            </Button>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title="Plays Cut">
            <Button
              className={classes.button}
              onClick={() => this._handlePlaysOpen(gamePk, feedDetails.videos)}
            >
              {feedDetails.videos.length}
            </Button>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title="Guids">
            <Button
              className={classes.button}
              onClick={() =>
                this._handlePlaysOpen(
                  gamePk,
                  _.concat(feedDetails.videos, feedDetails.missing)
                )
              }
            >
              {playCount}
            </Button>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title="Missing Plays">
            <Button
              className={classes.button}
              onClick={() => this._handlePlaysOpen(gamePk, feedDetails.missing)}
            >
              {feedDetails.missing.length}
            </Button>
          </Tooltip>
        </TableCell>
        <TableCell>
          {!inError ? (
            <React.Fragment />
          ) : (
            <Tooltip title="Resend Missing Plays">
              <Button
                color="primary"
                className={classes.button}
                onClick={() => this._handleGameResend()}
                disabled={store.resendingGame(gamePk, feedDetails.feed)}
              >
                <Send />
              </Button>
            </Tooltip>
          )}
        </TableCell>

        <Dialog
          open={this.state.playsOpen}
          onClose={this._handlePlaysClose}
          TransitionComponent={Transition}
          maxWidth={false}
        >
          <DialogTitle id="play-details">Play Details</DialogTitle>
          <PlayDetailsTable gamePk={gamePk} plays={store.selectedPlays} />
        </Dialog>

        <Dialog
          open={this.state.feedOpen}
          onClose={this._handleDialogClose}
          TransitionComponent={Transition}
        >
          <DialogTitle id="feed-details">Feed Details</DialogTitle>
          <List>
            {selectedFeed ? (
              selectedFeed.feeds.map(f => (
                <ListItem key={f.assetId} title={f.feed} divider>
                  <table>
                    <tbody>
                      <tr>
                        <td>Asset Id</td>
                        <td>{f.assetId}</td>
                      </tr>
                      <tr>
                        <td>Asset Name</td>
                        <td>{f.assetName}</td>
                      </tr>
                      <tr>
                        <td>Time Code Offset</td>
                        <td>{f.timecodeOffset}</td>
                      </tr>
                    </tbody>
                  </table>
                </ListItem>
              ))
            ) : (
              <Loading />
            )}
          </List>
        </Dialog>
      </TableRow>
    );
  }
}

export default inject("rootStore")(withStyles(styles)(observer(FeedRow)));
