import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = theme => ({});
const chooseIcon = diffType => {
  if (diffType === "ADD") {
    return <AddCircleIcon style={{ color: "green" }} />;
  } else if (diffType === "DELETE") {
    return <DeleteIcon style={{ color: "red" }} />;
  }
};

class SynonymDiffRow extends Component {
  state = {};

  render() {
    const { diffType, keyword, synonyms } = this.props;
    return (
      <React.Fragment>
        <TableRow key={keyword}>
          <TableCell>{chooseIcon(diffType)}</TableCell>
          <TableCell>{keyword}</TableCell>
          <TableCell>{synonyms.join(", ")}</TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}

export default inject("rootStore")(
  withStyles(styles)(observer(SynonymDiffRow))
);
