import axios from "axios";

const BASE_URL = process.env.REACT_APP_CLIPPY_BASE_URL;

export default class ClippyApi {
  getVideoUrl = (gamePk, playId, feedType) => {
    return axios
      .get(`${BASE_URL}/metadata/videos/${gamePk}/${playId}/${feedType}`)
      .then(r => r.data);
  };

  getVideos = (
    gamePk,
    playId,
    category = "ALL",
    obfuscate = true,
    useCdn = false
  ) => {
    return axios
      .get(
        `${BASE_URL}/metadata/videos/${gamePk}/${playId}?category=${category}&obfuscate=${obfuscate}&useCdn=${useCdn}`
      )
      .then(r => r.data);
  };
}
