import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import SynonymDiffRow from "./SynonymDiffRow";
import Snackbar from "./Snackbar";

const CURRENT_ENV = process.env.REACT_APP_ENV;
const TITLE = "title";
const HEADER = "header";
const PROD_ENV = "prod";
const styles = theme => ({
  tableCell: {
    padding: "0px 8px"
  },
  table: {
    minWidth: 700,
    minHeight: 500
  },
  dialogPaper: {
    minHeight: "80vh"
  }
});

const StyledButton = withStyles({
  root: {
    marginRight: 5,
    marginTop: 5
  }
})(Button);
const textForEnvironment = section => {
  if (CURRENT_ENV === PROD_ENV) {
    if (section === TITLE) {
      return " For Production.";
    }
    if (section === HEADER) {
      return " to production.";
    }
  }
  return ".";
};

class MigrateSynonymsDialog extends React.Component {
  snackbarIsOpen = false;
  state = {
    open: false,
    shouldMigrate: false
  };

  handleClose = () => {
    this._resetState();
  };

  handleSave = async () => {
    const { migrateSynonymsStore: store } = this.props.rootStore;
    store.migrateSynonyms();
    this._resetState();
  };
  handleClick = async e => {
    const { migrateSynonymsStore: store } = this.props.rootStore;
    await store.getSynonymsDiff(e);
    if (store.getHasChanges()) {
      this.setState({ open: true });
    } else {
      this._resetState();
    }
  };

  render() {
    const { migrateSynonymsStore: store } = this.props.rootStore;
    const { open } = this.state;
    return (
      <React.Fragment>
        <StyledButton
          size="medium"
          color="primary"
          variant="contained"
          disabled={store.refreshInProgress}
          onClick={e => this.handleClick(e)}
        >
          Migrate Synonyms ...
        </StyledButton>
        <Snackbar
          actions={[]}
          isOpen={store.snackbarIsOpen}
          message={store.snackbarMessage}
          variant={store.snackbarVariant}
          onCloseHandler={(e, r) => store.closeSnackbar(e, r)}
        />
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="migrate-synonyms-form">
            Review Synonyms{textForEnvironment(TITLE)}
          </DialogTitle>
          <DialogContent>
            You are about to migrate the following changes
            {textForEnvironment(HEADER)} Please review and confirm that these
            changes are correct.
            <hr className="solid" />
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={styles.tableCell}>&nbsp;</TableCell>
                  <TableCell className={styles.tableCell}>Keyword</TableCell>
                  <TableCell className={styles.tableCell}>Synonyms</TableCell>
                </TableRow>
                {store.diffList ? (
                  store.diffList.map(syn => (
                    <SynonymDiffRow
                      key={syn.keyword}
                      keyword={syn.keyword}
                      diffType={syn.synonymDiffType}
                      synonyms={syn.synonyms}
                    />
                  ))
                ) : (
                  <React.Fragment />
                )}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSave} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  _resetState() {
    this.setState({ open: false });
  }
}

export default inject("rootStore")(
  withStyles(styles)(observer(MigrateSynonymsDialog))
);
