import { action, decorate, observable } from "mobx";
import _ from "lodash";

class CheckOffsetStore {
  constructor(jaredApi, pitsApi) {
    this.jaredApi = jaredApi;
    this.pitsApi = pitsApi;
    this.gamePk = -1;
    this.playId = "";
    this.diamondAssetId = -1;
    this.diamondAssetName = "";
    this.timecodeOffset = -1;
    this.videoUrl = "";
    this.startTime = "";
    this.endTime = "";
    this.diamondAssets = [];
    this.videoLoading = false;
  }

  setPlayId(playId) {
    this.playId = playId;
    this.pitsApi.getPlayInfo(playId).then(d => {
      this.startTime = d["StartTime"];
      this.endTime = d["EndTime"];
      this.setGamePk(d["GamePk"]);
    });
  }

  setGamePk(gamePk) {
    this.gamePk = gamePk;
    if (gamePk > 0) {
      this.jaredApi.getAssets(gamePk).then(d => {
        this.diamondAssets = d.assets;
      });
    }
  }

  setDiamondAssetId(diamondAssetId) {
    this.diamondAssetId = diamondAssetId;
  }

  setDiamondAssetName(diamondAssetName) {
    this.diamondAssetName = diamondAssetName;
  }

  setTimeCodeOffset(timecodeOffset) {
    this.timecodeOffset = timecodeOffset;
  }

  setStartTime(startTime) {
    this.startTime = startTime;
  }

  setEndTime(endTime) {
    this.endTime = endTime;
  }

  setDiamondAssetNameFromId(assetId) {
    this.diamondAssetName = _.filter(
      this.diamondAssets,
      o => o.assetId === assetId
    )[0].assetName;
  }

  setDiamondTimecodeOffsetFromId(assetId) {
    this.timecodeOffset = _.filter(
      this.diamondAssets,
      o => o.assetId === assetId
    )[0].timeCodeOffset;
  }

  submit() {
    this.videoLoading = true;
    this.jaredApi
      .cutCustomClip(
        this.diamondAssetName,
        this.diamondAssetId,
        this.timecodeOffset,
        this.startTime,
        this.endTime
      )
      .then(r => {
        this.videoLoading = false;
        this.videoUrl = r.videoUrl;
      })
      .catch(_ => {
        this.videoLoading = false;
        alert("Failed to cut video");
      });
  }
}

decorate(CheckOffsetStore, {
  playId: observable,
  gamePk: observable,
  diamondAssetId: observable,
  diamondAssetName: observable,
  diamondAssets: observable,
  timecodeOffset: observable,
  submit: action,
  videoUrl: observable,
  startTime: observable,
  endTime: observable,
  videoLoading: observable
});

export default CheckOffsetStore;
