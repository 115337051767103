import _ from "lodash";
import { action, computed, decorate, observable } from "mobx";

class PitsMetadataStore {
  constructor(pitsApi) {
    this.pitsApi = pitsApi;
    this.fieldsDataIsLoaded = false;
    this.typesDataIsLoaded = false;

    this.fetchFields();
    this.fetchTypes();
  }

  fetchFields(searchType = "UNIFIED") {
    return this.pitsApi
      .getFields(searchType)
      .then(d => {
        this.fields = d.data;
        this.categories = this.getFieldCategories();
        _.each(this.fields, field => {
          if (
            this.getIsTypeAheadEnabled(field.id) === false &&
            this.fieldsWithDistinctValues.includes(field.name)
          ) {
            this.pitsApi
              .getFieldValues(field.id)
              .then(data => (field.values = data.data));
          }
        });
      })
      .then(() => {
        this.fieldsDataIsLoaded = true;
      });
  }

  fetchTypes() {
    return this.pitsApi.getTypes().then(d => {
      this.types = d.data;
      this.typesDataIsLoaded = true;
    });
  }

  getFieldInfo(fieldId) {
    const field = _.find(this.fields, function(o) {
      return o.id === fieldId;
    });
    if (field) {
      return field;
    }
    return {
      category: "",
      name: "",
      values: []
    };
  }

  getFieldCategories() {
    return _.sortBy(_.uniq(_.map(this.fields, f => f.category)));
  }

  getFields(category) {
    return _.sortBy(
      _.filter(
        this.fields,
        f => (category && f.category === category) || !category
      )
    );
  }

  getFieldMappingIdByName(fieldName) {
    const field = _.filter(this.fields, o => o.name === fieldName);
    if (field.length === 1) {
      return field[0].id;
    }
    return null;
  }

  get fieldsWithDistinctValues() {
    return _.reduce(
      this.fields,
      (acc, field) => {
        if (field.supportsDistinctValues) {
          acc.push(field.name);
        }
        return acc;
      },
      []
    );
  }

  getIsTypeAheadEnabled(fieldId) {
    const field = this.getFieldInfo(fieldId);
    return field && field.typeAheadEnabled;
  }

  get fieldsByCategory() {
    return _.chain(this.fields)
      .sortBy(field => field.name)
      .groupBy(field => field.category.toUpperCase())
      .value();
  }

  get typesByName() {
    return _.fromPairs(_.map(this.types, t => [t.name, t]));
  }

  get isInitialized() {
    return this.fieldsDataIsLoaded && this.typesDataIsLoaded;
  }
}

decorate(PitsMetadataStore, {
  categories: observable,
  fieldNames: observable,
  fields: observable,
  fieldsDataIsLoaded: observable,
  typesDataIsLoaded: observable,
  getFieldInfo: action,
  getFields: action,
  isTypeAheadEnabled: action,
  fieldsWithDistinctValues: computed,
  fieldsByCategory: computed,
  isInitialized: computed
});

export default PitsMetadataStore;
