import { inject, observer } from "mobx-react";
import React from "react";
import Dialog from "@material-ui/core/Dialog";

class VideoPlayer extends React.Component {
  render() {
    const {
      rootStore: { videoPlayerStore }
    } = this.props;

    return (
      <Dialog
        onClose={() => videoPlayerStore.hidePlayer()}
        open={videoPlayerStore.isOpen}
        maxWidth={false}
      >
        <video
          src={videoPlayerStore.videoSrc}
          width="960px"
          height="540px"
          type="video/mp4"
          autoPlay
          controls
        />
      </Dialog>
    );
  }
}

export default inject("rootStore")(observer(VideoPlayer));
