import axios from "axios";
import moment from "moment";

const BASE_URL = process.env.REACT_APP_SPORTY_API_BASE_URL;

export default class SportyApi {
  getIssue = id => {
    return axios.get(`${BASE_URL}/issues/${id}`).then(r => r.data);
  };

  getIssues(limit = 10, page = 0) {
    return axios
      .get(`${BASE_URL}/issues`, { params: { limit, page } })
      .then(r => r.data);
  }

  getRules(date, frequency = "ALL", enabled = true, limit = 10, page = 0) {
    const createdFrom = moment.utc(date).format();

    return axios
      .get(`${BASE_URL}/rules`, {
        params: { createdFrom, frequency, enabled, limit, page }
      })
      .then(r => r.data);
  }

  getClipsForRule(ruleId, date) {
    // Take a game date and find clips made during game time

    // 11 am EDT -> 6 am EDT next day
    const from = moment
      .utc(date)
      .hour(15)
      .format();
    const to = moment
      .utc(date)
      .hour(34)
      .format();

    return axios
      .get(`${BASE_URL}/rules/${ruleId}/clips`, { params: { from, to } })
      .then(r => r.data);
  }

  putIssue = issue => {
    return axios.put(`${BASE_URL}/issue/${issue.id}`, issue).then(r => r.data);
  };
}
