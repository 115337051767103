import { action, decorate, observable } from "mobx";
const ERROR_VARIANT = "error";
const WARNING_VARIANT = "warning";
const INFO_VARIANT = "info";
const SUCCESS_VARIANT = "success";
class MigrateSynonymsStore {
  diffList = [];
  hasChanges = false;
  snackbarIsOpen = false;
  snackbarMessage = "No changes found for migration.";
  snackbarVariant = WARNING_VARIANT;
  refreshInProgress = false;

  constructor(pitsApi, aloftApi) {
    this.pitsApi = pitsApi;
    this.aloftApi = aloftApi;
  }

  migrateSynonyms() {
    this.refreshInProgress = true;
    this.pitsApi
      .migrateSynonyms()
      .then(r => {
        const result = r.data[0];
        if (result.success) {
          this.snackbarMessage = `Migration successful with key ${
            result.backupKey
          }. Calling FTS refresh.`;
          this.refreshFreeTextData();
        } else {
          this.snackbarMessage = `Migration failed with error: ${
            result.errorMessage
          }`;
          this.snackbarVariant = ERROR_VARIANT;
          this.snackbarIsOpen = true;
          this.refreshInProgress = false;
        }
      })
      .catch(reason => {
        this.snackbarMessage = `Migration failed due to exception. ${
          reason.message
        }`;
        this.snackbarIsOpen = true;
        this.snackbarVariant = ERROR_VARIANT;
        this.refreshInProgress = false;
      });
  }

  refreshFreeTextData() {
    this.refreshInProgress = true;
    this.snackbarVariant = INFO_VARIANT;
    this.snackbarIsOpen = true;
    this.snackbarMessage = "Refresh search index in progress ...";
    this.aloftApi
      .refreshFreeTextSearch()
      .then(() => {
        this.refreshInProgress = false;
        this.snackbarVariant = SUCCESS_VARIANT;
        this.snackbarMessage = "Refresh of search index has completed.";
        this.snackbarIsOpen = true;
      })
      .catch(result => {
        this.refreshInProgress = false;
        this.snackbarMessage = `Refresh of search index failed due to exception: ${result}`;
        this.snackbarVariant = ERROR_VARIANT;
        this.snackbarIsOpen = true;
      });
  }

  getHasChanges() {
    return this.hasChanges;
  }

  closeSnackbar(event, value) {
    this.snackbarIsOpen = false;
    this.snackbarMessage = "";
    this.snackbarVariant = INFO_VARIANT;
  }

  getSynonymsDiff(event) {
    return this.pitsApi
      .getSynonymsDiff()
      .then(r => {
        const results = r.data[0];
        if (results.success) {
          this.hasChanges = results.changed;
          if (this.hasChanges === false) {
            this.snackbarIsOpen = true;
            this.snackbarVariant = INFO_VARIANT;
            this.snackbarMessage = "No changes found for migration.";
          } else {
            this.diffList = results.changes;
          }
        } else {
          this.snackbarIsOpen = true;
          this.snackbarVariant = ERROR_VARIANT;
          this.snackbarMessage = `Unable to get diffs for synonyms. ${
            results.errorMessage
          }`;
        }
      })
      .catch(reason => {
        this.snackbarIsOpen = true;
        this.snackbarVariant = ERROR_VARIANT;
        this.snackbarMessage = `Unable to get diffs due to exception. ${
          reason.message
        }`;
      });
  }
}

decorate(MigrateSynonymsStore, {
  diffList: observable,
  refreshInProgress: observable,
  getSynonymsDiff: action,
  setSnackbarOpen: action,
  closeSnackbar: action,
  snackbarVariant: observable,
  snackbarIsOpen: observable
});

export default MigrateSynonymsStore;
