import React from "react";
import { inject } from "mobx-react";
import { Link } from "react-router-dom";
import MAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import logo from "../assets/mlb-logo.png";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import rem from "polished/lib/helpers/rem";
import Menu from "./AppBarMenu";

const Logo = styled.img`
  margin: 0 20px;
  width: 100px;
`;

const StyledButton = withStyles(theme => ({
  root: {
    color: "#9e9e9e",
    textTransform: "none",
    margin: rem(5, "16px"),
    paddingLeft: rem(12, "16px"),
    paddingRight: rem(12, "16px"),
    fontWeight: 400,
    fontSize: rem(13, "16px")
  }
}))(Button);

const NavItem = React.forwardRef((props, ref) => (
  <StyledButton ref={ref} component={Link} size="small" {...props} />
));

class AppBar extends React.PureComponent {
  render() {
    const { routerStore } = this.props.rootStore;

    return (
      <MAppBar
        position="static"
        color="inherit"
        elevation={0}
        style={{ borderBottom: "1px solid #EEE" }}
      >
        <Toolbar>
          <Link to="/">
            <Logo src={logo} />
          </Link>
          {routerStore.navPages.map((link, idx) => {
            if (link.menuLabel) {
              return (
                <Menu
                  key={idx}
                  label={link.menuLabel}
                  component={StyledButton}
                  items={link.items.map(link => ({
                    component: NavItem,
                    to: link.path,
                    label: link.label
                  }))}
                />
              );
            }
            return (
              <NavItem key={idx} to={link.path}>
                {link.label}
              </NavItem>
            );
          })}
        </Toolbar>
      </MAppBar>
    );
  }
}

export default inject("rootStore")(AppBar);
