import axios from "axios";
import _ from "lodash";

const BASE_URL = process.env.REACT_APP_PITS_BASE_URL;

class PitsApi {
  getQueryInfo(query) {
    return axios
      .post(`${BASE_URL}/search/info`, {
        queries: [query],
        searchType: "UNIFIED",
        queryType: "STRUCTURED",
        languagePreference: "EN"
      })
      .then(r => r.data);
  }

  search(q, limit = 10, page = 0) {
    return axios
      .get(`${BASE_URL}/search`, { params: { q, limit, page } })
      .then(r => r.data);
  }

  getSynonyms(page = 0, limit = 20) {
    return axios
      .get(`${BASE_URL}/fts/synonyms?page=${page}&limit=${limit}`)
      .then(r => r.data);
  }

  deleteSynonym(value, synonym) {
    return axios.delete(`${BASE_URL}/fts/synonyms/${value}/${synonym}`);
  }

  addSynonym(value, synonym) {
    return axios.put(`${BASE_URL}/fts/synonyms/add/${value}/${synonym}`);
  }

  migrateSynonyms() {
    return axios.post(`${BASE_URL}/fts/synonyms/migrate`).then(r => r.data);
  }

  getSynonymsDiff() {
    return axios.get(`${BASE_URL}/fts/synonyms/diff`).then(r => r.data);
  }

  getFields(searchType = "UNIFIED") {
    return axios
      .get(`${BASE_URL}/metadata/fields?searchType=${searchType}`)
      .then(r => r.data);
  }

  getFieldValues(fieldId, searchType = "UNIFIED") {
    return axios
      .get(
        `${BASE_URL}/metadata/fields/${fieldId}/values?searchType=${searchType}`
      )
      .then(r => r.data);
  }

  getTypes() {
    return axios.get(`${BASE_URL}/metadata/types`).then(r => r.data);
  }

  getPlayInfo(playId) {
    return this.search(
      'PlayId = "' +
        playId +
        '" Returning StartTime, PitchTime, EndTime, GamePk'
    ).then(d => {
      if (d.data.length === 1) {
        const playInfo = d.data[0];
        const fields = playInfo.fields;
        const startTime = _.find(fields, o => o.name === "StartTime");
        const pitchTime = _.find(fields, o => o.name === "PitchTime");
        const endTime = _.find(fields, o => o.name === "EndTime");
        const gamePk = _.find(fields, o => o.name === "GamePk");
        return {
          PlayId: playId,
          StartTime: startTime && startTime.value,
          PitchTime: pitchTime && pitchTime.value,
          EndTime: endTime && endTime.value,
          GamePk: gamePk && gamePk.value
        };
      } else {
        return {
          PlayId: playId,
          StartTime: "",
          PitchTime: "",
          EndTime: "",
          GamePk: ""
        };
      }
    });
  }

  getPinnedSearchSuggestions() {
    return axios
      .get(`${BASE_URL}/fts/search/suggestions/type/pinned`)
      .then(r => r.data);
  }

  getRandomizedSearchSuggestions() {
    return axios
      .get(`${BASE_URL}/fts/search/suggestions/type/randomized`)
      .then(r => r.data);
  }

  addPinnedSearchSuggestion(suggestedSearch, sortOrder) {
    return axios
      .post(`${BASE_URL}/fts/search/suggestions/pinned`, {
        suggestedSearch,
        sortOrder
      })
      .then(r => r.data);
  }

  addRandomizedSearchSuggestion(suggestedSearch) {
    return axios
      .post(`${BASE_URL}/fts/search/suggestions/randomized/${suggestedSearch}`)
      .then(r => r.data);
  }

  deleteSearchSuggestion(id) {
    return axios.delete(`${BASE_URL}/fts/search/suggestions/${id}`);
  }

  reorderSearchSuggestions(reorderList) {
    return axios
      .put(`${BASE_URL}/fts/search/suggestions/reorder`, {
        reorderList
      })
      .then(r => r.data);
  }

  getMacros() {
    return axios.get(`${BASE_URL}/fts/macros`).then(r => r.data);
  }

  getMacro(macroId) {
    return axios.get(`${BASE_URL}/fts/macros/${macroId}`).then(r => r.data);
  }

  insertMacro(macro) {
    return axios
      .post(`${BASE_URL}/fts/macros`, { macros: [macro] })
      .then(r => r.data);
  }

  updateMacro(id, macro) {
    return axios
      .put(`${BASE_URL}/fts/macros/${id}`, { ...macro })
      .then(r => r.data);
  }

  deleteMacro(id) {
    return axios.delete(`${BASE_URL}/fts/macros/${id}`).then(r => r.data);
  }
}

PitsApi.BASE_URL = BASE_URL;

export default PitsApi;

export const getPlayDoc = playId => {
  return `${BASE_URL}/plays/${playId}`;
};
