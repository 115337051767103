import axios from "axios";

const BASE_URL = process.env.REACT_APP_TYPE_AHEAD_URL;

class TypeAheadApi {
  getSuggestions(searchTerm, fieldId, searchType = "UNIFIED") {
    return axios
      .get(
        `${BASE_URL}/typeahead/suggestions/${searchTerm}?fieldId=${fieldId}&searchType=${searchType}`
      )
      .then(r => r.data);
  }
}

TypeAheadApi.BASE_URL = BASE_URL;
export default TypeAheadApi;
