import VideoDiagnosticStore from "./videoDiagnosticStore";
import RouterStore from "./routerStore";
import PlayDiagnosticStore from "./playDiagnosticStore";
import IssuesStore from "./issuesStore";
import AdjustIssueStore from "./adjustIssueStore";
import VideoPlayerStore from "./videoPlayerStore";
import GameAuditStore from "./gameAuditStore";
import PlayAuditStore from "./playAuditStore";
import ClippyApi from "../httpClients/clippyApi";
import JaredApi from "../httpClients/jaredApi";
import RuleStore from "../stores/ruleStore";
import StatsApi from "../httpClients/statsApi";
import SportyApi from "../httpClients/sportyApi";
import PitsApi from "../httpClients/pitsApi";
import AloftApi from "../httpClients/aloftApi";
import VideoAssetOverridesStore from "./videoAssetOverridesStore";
import AssetOverrideDialogStore from "./assetOverrideDialogStore";
import CheckOffsetStore from "./checkOffsetStore";
import ClearCacheStore from "./clearCacheStore";
import SynonymsStore from "./synonymsStore";
import PitsMetadataStore from "./pitsMetadataStore";
import MigrateSynonymsStore from "./migrateSynonymsStore";
import TypeAheadApi from "../httpClients/typeAheadApi";
import TypeAheadStore from "./typeAheadStore";
import SearchSuggestionsStore from "./searchSuggestionsStore";
import SportyBookApi from "../httpClients/sportyBookApi";
import BivoApi from "../httpClients/bivoApi";
import MacrosStore from "./macrosStore";
import MacroStore from "./macroStore";

class RootStore {
  constructor() {
    this.clippyApi = new ClippyApi();
    this.jaredApi = new JaredApi();
    this.pitsApi = new PitsApi();
    this.statsApi = new StatsApi();
    this.sportyApi = new SportyApi();
    this.aloftApi = new AloftApi();
    this.typeAheadApi = new TypeAheadApi();
    this.sportyBookApi = new SportyBookApi();
    this.bivoApi = new BivoApi();

    this.ruleStore = new RuleStore(this.sportyApi, this.pitsApi, this.jaredApi);
    this.videoPlayerStore = new VideoPlayerStore();
    this.videoDiagnosticStore = new VideoDiagnosticStore(this.jaredApi, this.statsApi);
    this.playDiagnosticStore = new PlayDiagnosticStore(this.jaredApi);
    this.videoAssetOverridesStore = new VideoAssetOverridesStore(this.jaredApi);
    this.assetOverrideDialogStore = new AssetOverrideDialogStore(this.jaredApi, this.videoAssetOverridesStore);
    this.routerStore = new RouterStore();
    this.issuesStore = new IssuesStore(this.sportyApi, this.clippyApi);
    this.adjustIssueStore = new AdjustIssueStore(this.sportyApi, this.clippyApi, this.jaredApi, this.issuesStore);
    this.synonymsStore = new SynonymsStore(this.pitsApi);
    this.pitsMetadataStore = new PitsMetadataStore(this.pitsApi);
    this.migrateSynonymsStore = new MigrateSynonymsStore(this.pitsApi, this.aloftApi);
    this.checkOffsetStore = new CheckOffsetStore(this.jaredApi, this.pitsApi);
    this.gameAuditStore = new GameAuditStore(this.bivoApi, this.sportyBookApi, this.statsApi);
    this.playAuditStore = new PlayAuditStore(this.sportyBookApi, this.pitsApi, this.clippyApi, this.jaredApi);
    this.typeAheadStore = new TypeAheadStore(this.typeAheadApi);
    this.clearCacheStore = new ClearCacheStore(this.jaredApi);
    this.searchSuggestionsStore = new SearchSuggestionsStore(this.pitsApi);
    this.macrosStore = new MacrosStore(this.pitsApi, this.aloftApi, this.pitsMetadataStore);
    this.macroStore = new MacroStore(this.pitsApi, this.pitsMetadataStore, this.routerStore, this.macrosStore);
  }
}

export default RootStore;
