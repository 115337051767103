import { inject, observer } from "mobx-react";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead/TableHead";
import RuleRow from "./RuleRow";
import Paper from "@material-ui/core/Paper";
const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    alignContent: "space-between"
  },
  containerItem: {
    margin: "auto",
    padding: `0 ${theme.spacing.unit}px`
  }
});

const columns = [
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  { id: "ruleId", numeric: false, disablePadding: false, label: "Rule Id" },
  { id: "name", numeric: false, disablePadding: false, label: "Name" },
  {
    id: "frequency",
    numeric: false,
    disablePadding: false,
    label: "Frequency"
  },
  { id: "clips", numeric: false, disablePadding: false, label: "Clips" },
  {
    id: "search-results",
    numeric: false,
    disablePadding: false,
    label: "Search Results"
  },
  {
    id: "missing",
    numeric: false,
    disablePadding: false,
    label: "Missing"
  }
];

class RuleDetailsTable extends React.Component {
  _handleChangePage = (event, page) => {
    const { ruleStore: store } = this.props.rootStore;
    store.setPage(page);
  };

  _handleChangeRowsPerPage = event => {
    const { ruleStore: store } = this.props.rootStore;
    store.setRowsPerPage(event.target.value);
  };

  render() {
    const { rules, clips } = this.props;
    const { ruleStore: store } = this.props.rootStore;
    const total = store.totalRules;
    const page = store.page;
    const rowsPerPage = store.rowsPerPage;

    return (
      <Paper>
        <Table>
          {rules && rules.length > 0 ? (
            <TableHead>
              <TableRow>
                {columns.map(column => {
                  return (
                    <TableCell
                      key={column.id}
                      numeric={column.numeric}
                      padding={column.disablePadding ? "none" : "default"}
                    >
                      {column.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {rules
              ? rules.map(r => (
                  <RuleRow key={r.ruleId} rule={r} clips={clips} />
                ))
              : null}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total ? total : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this._handleChangePage}
          onChangeRowsPerPage={this._handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

export default inject("rootStore")(
  withStyles(styles)(observer(RuleDetailsTable))
);
