import React, { forwardRef } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { withStyles } from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import withAuth from "../containers/withAuth";
import { SPORTY_ROLES } from "../constants/authConstants";
import Snackbar from "./Snackbar";

const Suggestion = forwardRef((props, ref) => {
  const {
    suggestion,
    additionalActions,
    onDeleteSuggestion,
    ...otherProps
  } = props;

  return (
    <ListItem component="li" ref={ref} {...otherProps}>
      <ListItemText primary={suggestion} />
      <ListItemIcon>
        <Tooltip title="Preview">
          <Link
            component={IconButton}
            href={`https://www.mlb.com/video/search?q=${suggestion}&qt=FREETEXT`}
            target="_blank"
            rel="noopener"
          >
            <VisibilityIcon color="primary" />
          </Link>
        </Tooltip>
      </ListItemIcon>
      <ListItemIcon>
        <Tooltip title="Delete">
          <IconButton aria-label="Delete" onClick={onDeleteSuggestion}>
            <DeleteForeverIcon color="secondary" />
          </IconButton>
        </Tooltip>
      </ListItemIcon>
      {additionalActions}
    </ListItem>
  );
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((item, index) => ({
    ...item,
    sortOrder: index
  }));
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,
  ...(isDragging && {
    background: "rgb(235,235,235)"
  })
});

class SortedList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.suggestions
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.suggestions !== prevProps.suggestions) {
      this.setState({
        items: this.props.suggestions
      });
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items
    });

    this.props.onReorderSuggestions(items);
  }

  render() {
    const { onDeleteSuggestion } = this.props;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <List ref={provided.innerRef}>
              {this.state.items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={`${item.id}-${item.suggestion}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <Suggestion
                      ref={provided.innerRef}
                      suggestion={item.suggestion}
                      onDeleteSuggestion={() => onDeleteSuggestion(item.id)}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      additionalActions={
                        <ListItemIcon>
                          <Tooltip title="Reorder">
                            <IconButton
                              aria-label="Reorder"
                              {...provided.dragHandleProps}
                            >
                              <DragIndicatorIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemIcon>
                      }
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

const PinnedList = props => {
  const { suggestions, onDeleteSuggestion } = props;

  return (
    <List>
      {suggestions
        .slice(0)
        .reverse()
        .map(item => (
          <Suggestion
            key={item.id}
            suggestion={item.suggestion}
            onDeleteSuggestion={() => onDeleteSuggestion(item.id)}
          />
        ))}
    </List>
  );
};

const StyledTypography = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(Typography);

class Suggestions extends React.Component {
  state = {
    value: ""
  };

  componentDidMount() {
    this.props.getSearchSuggestions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.suggestions !== prevProps.suggestions) {
      this.setState({
        value: ""
      });
    }
  }

  render() {
    const {
      title,
      suggestions,
      onDeleteSuggestion,
      onAddSuggestion,
      onReorderSuggestions,
      sorted
    } = this.props;

    return (
      <>
        <StyledTypography component="h2" variant="h5" color="primary">
          {title}
        </StyledTypography>
        <Divider />
        {sorted ? (
          <SortedList
            suggestions={suggestions}
            onDeleteSuggestion={onDeleteSuggestion}
            onReorderSuggestions={onReorderSuggestions}
          />
        ) : (
          <PinnedList
            suggestions={suggestions}
            onDeleteSuggestion={onDeleteSuggestion}
          />
        )}
        <Divider />
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7} md={9} lg={10}>
              <TextField
                required
                label="Search Suggestion"
                size="small"
                fullWidth
                variant="outlined"
                value={this.state.value}
                onChange={event => this.setState({ value: event.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={3} lg={2} style={{ display: "flex" }}>
              <Button
                disabled={!!!this.state.value}
                size="medium"
                color="primary"
                variant="contained"
                fullWidth
                disableElevation
                onClick={() => onAddSuggestion(this.state.value)}
              >
                Add Suggestion
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}

class SearchSuggestions extends React.Component {
  render() {
    const { searchSuggestionsStore: store } = this.props.rootStore;
    const pinnedSuggestions = toJS(store.pinnedSuggestions) || [];
    const randomizedSuggestions = toJS(store.randomizedSuggestions) || [];

    return (
      <Box px={6} pb={6}>
        <h1>Search Suggestions</h1>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper>
              <Suggestions
                title="Pinned"
                suggestions={pinnedSuggestions}
                getSearchSuggestions={() => store.getPinnedSearchSuggestions()}
                onAddSuggestion={value =>
                  store.addPinnedSearchSuggestion(
                    value,
                    (pinnedSuggestions[pinnedSuggestions.length - 1]
                      ?.sortOrder ?? 0) + 1
                  )
                }
                onDeleteSuggestion={id => store.deleteSearchSuggestion(id)}
                onReorderSuggestions={list =>
                  store.reorderSearchSuggestions(list)
                }
                sorted
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Suggestions
                title="Randomized"
                suggestions={randomizedSuggestions}
                getSearchSuggestions={() =>
                  store.getRandomizedSearchSuggestions()
                }
                onAddSuggestion={value =>
                  store.addRandomizedSearchSuggestion(value)
                }
                onDeleteSuggestion={id => store.deleteSearchSuggestion(id)}
                onReorderSuggestions={list =>
                  store.reorderSearchSuggestions(list)
                }
              />
            </Paper>
          </Grid>
        </Grid>
        <Snackbar
          isOpen={store.snackbarIsOpen}
          message={store.snackbarMessage}
          variant={store.snackbarVariant}
          onCloseHandler={(e, r) => store.closeSnackbar(e, r)}
          autoHideDuration={4000}
        />
      </Box>
    );
  }
}

export default withAuth(
  inject("rootStore")(observer(SearchSuggestions)),
  SPORTY_ROLES
);
