import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Select from "@material-ui/core/Select";
import { inject, observer } from "mobx-react";

class FieldPicker extends React.Component {
  render() {
    const { synonym } = this.props;
    const { pitsMetadataStore: store } = this.props.rootStore;
    const fieldId = synonym.fieldMappingId;
    const fieldInfo = store.getFieldInfo(fieldId);
    const categoryToUse = synonym.category
      ? synonym.category
      : fieldInfo.category;
    const fields = store.getFields(categoryToUse);
    const nameToUse = synonym.name ? synonym.name : fieldInfo.name;
    return (
      <Select
        value={nameToUse}
        disabled={synonym.fromDb}
        onChange={e => {
          synonym.setName(e.target.value);
          synonym.setFieldMappingId(
            store.getFieldMappingIdByName(e.target.value)
          );
        }}
      >
        {fields
          ? fields.map(c => (
              <MenuItem key={c.id} value={c.name}>
                {c.name}
              </MenuItem>
            ))
          : []}
      </Select>
    );
  }
}

export default inject("rootStore")(observer(FieldPicker));
