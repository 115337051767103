import React from "react";
import withAuth from "../../containers/withAuth";
import { inject, observer } from "mobx-react";
import { SPORTY_ROLES } from "../../constants/authConstants";
import Grid from "@material-ui/core/Grid";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import TypeAheadDropDown from "../TypeAheadDropDown";
import { Autocomplete } from "@material-ui/lab";
import { OpenInNew } from "@material-ui/icons";
import styled from "styled-components";

const Container = styled.div`
  margin-top: 20px;
  padding: 0 100px;
`;

class Macro extends React.Component {
  componentDidMount() {
    const { macroStore } = this.props.rootStore;
    const macroId = this.props.match.params.id;
    if (macroId && macroId.toUpperCase() !== "NEW") {
      macroStore.setMacro(parseInt(macroId));
    }
  }

  handleMultiSelect = (event, clause) => {
    const {
      target: { value }
    } = event;
    clause.values = value === "string" ? value.split(",") : value;
  };

  render() {
    const { pitsMetadataStore, macroStore, routerStore } = this.props.rootStore;

    const distinctValueSelect = clause =>
      pitsMetadataStore.getIsTypeAheadEnabled(clause.fieldId) ? (
        <TypeAheadDropDown
          multiple={true}
          value={clause.values.map(v => v.value)}
          onSelect={values => {
            // If loading an existing macro the values are objects, but typeahead only returns the underlying value
            // convert to an object to mimic behavior of loading an existing macro
            clause.values = values.map(v => {
              return { label: v, value: v };
            });
          }}
          onClear={() => (clause.values = [])}
          fieldMappingId={clause.fieldId}
        />
      ) : (
        <Autocomplete
          multiple
          limitTags={3}
          size={"small"}
          id={`autocomplete-${clause.fieldId}`}
          options={pitsMetadataStore
            .getFieldInfo(clause.fieldId)
            .values.map(f => ({
              label: f.displayValue,
              value: f.value
            }))}
          filterSelectedOptions
          getOptionLabel={option => option.label}
          getOptionSelected={(option, value) => option.value === value.value}
          value={clause.values}
          onChange={(e, newValues) => {
            clause.values = newValues;
          }}
          renderInput={params => (
            <TextField {...params} variant="standard" label="Select" />
          )}
        />
      );

    return (
      <Container>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} sm={10} md={9} lg={9}>
            <TextField
              label="Macro"
              variant="outlined"
              value={macroStore.text}
              size={"small"}
              margin={"normal"}
              fullWidth
              onChange={e => {
                macroStore.setText(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {macroStore.query}
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={3} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              disabled={!macroStore.isValid}
              endIcon={<OpenInNew />}
              style={{ marginTop: "16px" }}
              onClick={() => {
                macroStore.previewMacro();
              }}
            >
              Preview
            </Button>
          </Grid>

          {macroStore.clauses.map((clause, i) => {
            return (
              <Grid
                container
                key={`${clause.text}-${i}`}
                spacing={2}
                style={{ margin: "0" }}
              >
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="category">Category</InputLabel>
                    <Select
                      value={clause.category}
                      id="category"
                      label={"Category"}
                      onChange={e => {
                        clause.setCategory(e.target.value);
                      }}
                    >
                      {pitsMetadataStore.categories.map((c, i) => {
                        return (
                          <MenuItem key={i} value={c}>
                            {c}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <FormControl disabled={clause.isFieldNameDisabled} fullWidth>
                    <InputLabel id="subcategory">Subcategory</InputLabel>
                    <Select
                      id="subcategory"
                      value={clause.fieldId === -1 ? "" : clause.fieldId}
                      onChange={e => {
                        const field = pitsMetadataStore.getFieldInfo(
                          e.target.value
                        );
                        clause.setField(field);
                      }}
                    >
                      {!clause.isFieldNameDisabled &&
                        pitsMetadataStore.fieldsByCategory[
                          clause.category.toUpperCase()
                        ].map((field, i) => (
                          <MenuItem key={i} value={Number(field.id)}>
                            {field.displayName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={2}>
                  <FormControl disabled={clause.isOperatorDisabled} fullWidth>
                    <InputLabel id="operator">Operator</InputLabel>
                    <Select
                      id={"operator"}
                      value={clause.operator}
                      onChange={e => {
                        clause.operator = e.target.value;
                      }}
                    >
                      {!clause.isOperatorDisabled &&
                        pitsMetadataStore.typesByName[
                          pitsMetadataStore.getFieldInfo(clause.fieldId).type
                        ].supportedComparators.map((comparator, i) => (
                          <MenuItem key={i} value={comparator.symbol}>
                            {comparator.displayName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth>
                    {pitsMetadataStore.fieldsWithDistinctValues.includes(
                      clause.fieldName
                    ) ? (
                      distinctValueSelect(clause)
                    ) : (
                      <TextField
                        id="value"
                        label="Value"
                        value={clause.value}
                        disabled={
                          clause.isFieldNameDisabled ||
                          clause.isOperatorDisabled
                        }
                        onChange={e => {
                          clause.value = e.target.value;
                        }}
                      />
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  {macroStore.clauses.length > 1 && (
                    <IconButton
                      aria-label="delete"
                      style={{ padding: "8px" }}
                      onClick={() => {
                        macroStore.removeClause(i);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                  <IconButton
                    aria-label="add"
                    style={{ padding: "8px" }}
                    onClick={() => {
                      macroStore.addClause();
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
                {clause.logicalConjunctionOperator !== "" && (
                  <Grid item>
                    <FormControl size="small">
                      <InputLabel id={`clause-${i}-conjunction`}>
                        Conjunction
                      </InputLabel>
                      <Select
                        labelId={`clause-${i}-conjunction`}
                        id={`clause-${i}-conjunction`}
                        value={clause.logicalConjunctionOperator}
                        label="Conjunction"
                        onChange={e => {
                          clause.logicalConjunctionOperator = e.target.value;
                        }}
                      >
                        <MenuItem value="AND">AND</MenuItem>
                        <MenuItem value="OR">OR</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            );
          })}
        </Grid>
        <Button
          color={"default"}
          variant={"contained"}
          style={{ float: "left", marginTop: "1.5rem" }}
          onClick={() => {
            macroStore.reset();
            routerStore.push("/macros");
          }}
        >
          Cancel
        </Button>
        <Button
          color={"primary"}
          variant={"contained"}
          disabled={!macroStore.isValid || macroStore.saving}
          style={{ float: "right", marginTop: "1.5rem" }}
          onClick={() => {
            macroStore.saveMacro();
          }}
        >
          {macroStore.saving ? <CircularProgress size={"1.75rem"} /> : "Save"}
        </Button>
        <Backdrop
          style={{ color: "#fff", zIndex: 999 }}
          open={macroStore.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

export default withAuth(inject("rootStore")(observer(Macro)), SPORTY_ROLES);
