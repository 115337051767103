import { inject, observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import CreatableSelect from "react-select/creatable";
import { Button } from "@material-ui/core";
import FieldCategoryPicker from "./FieldCategoryPicker";
import FieldPicker from "./FieldPicker";
import MigrateSynonymsDialog from "./MigrateSynonymsDialog";
import withAuth from "../containers/withAuth";
import { SPORTY_ROLES } from "../constants/authConstants";
import TableContainer from "@material-ui/core/TableContainer";
import Snackbar from "./Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import TypeAheadDropDown from "./TypeAheadDropDown";

const Container = styled.div`
  margin-top: 20px;
  padding: 0 50px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = withStyles({
  root: {
    marginRight: 5,
    marginTop: 5
  }
})(Button);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  tableCell: {
    padding: "0px 4px"
  },
  table: {
    minWidth: 700
  },
  allNotificationsSwitch: {
    float: "right"
  },
  chip: {
    marginRight: 5
  },
  button: {
    marginTop: 5
  },
  error: {
    backgroundColor: "#ff0000"
  }
});

const InputTextField = props => (
  <TextField
    fullWidth={true}
    InputProps={{ disableUnderline: false }}
    {...props}
  />
);
const StyledTableContainer = withStyles(theme => ({
  root: {
    width: "auto"
  }
}))(TableContainer);

class SynonymsTable extends React.Component {
  _handleChangePage = (event, page) => {
    const { synonymsStore: store } = this.props.rootStore;
    store.setPage(page);
  };

  _handleChangeRowsPerPage = event => {
    const { synonymsStore: synStore } = this.props.rootStore;
    synStore.setRowsPerPage(event.target.value);
  };

  render() {
    const { classes } = this.props;
    const { synonymsStore: synStore } = this.props.rootStore;
    const { migrateSynonymsStore: migrationStore } = this.props.rootStore;
    const total = synStore.totalRows;
    const page = synStore.currentPage;
    const rowsPerPage = synStore.rowsPerPage;

    return (
      <Container>
        {migrationStore.refreshInProgress ? (
          <LoadingWrapper>
            <CircularProgress />
          </LoadingWrapper>
        ) : null}
        <Snackbar
          actions={[]}
          isOpen={migrationStore.snackbarIsOpen}
          message={migrationStore.snackbarMessage}
          variant={migrationStore.snackbarVariant}
          onCloseHandler={(e, r) => migrationStore.closeSnackbar(e, r)}
        />
        <Typography variant="h1">
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Synonyms</TableCell>
                  <TableCell align="right">
                    <StyledButton
                      size="medium"
                      variant="contained"
                      disabled={migrationStore.refreshInProgress}
                      onClick={() => migrationStore.refreshFreeTextData()}
                    >
                      Refresh Search Index
                    </StyledButton>
                    <MigrateSynonymsDialog />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Typography>
        <Paper className={classes.root}>
          <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Category</TableCell>
                  <TableCell className={classes.tableCell}>Field</TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ width: "25%" }}
                  >
                    Keyword
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ width: "50%" }}
                  >
                    Synonyms
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {synStore.synonyms ? (
                  synStore.synonyms.map((syn, index) => (
                    <TableRow
                      key={"sr-" + index}
                      className={syn.inError ? classes.error : ""}
                    >
                      <TableCell component="th" scope="row">
                        <FieldCategoryPicker synonym={syn} />
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <FieldPicker synonym={syn} />
                      </TableCell>
                      <TableCell>
                        {syn.fromDb ? (
                          <InputTextField
                            key={"syn-keyword-" + index}
                            type="text"
                            value={syn.keyword}
                            disabled={syn.fromDb || syn.name === ""}
                          />
                        ) : (
                          <TypeAheadDropDown
                            multiple={false}
                            onSelect={value =>
                              syn.setKeyword(value ? value : "")
                            }
                            fieldMappingId={syn.fieldMappingId}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {/*https://react-select.com/creatable*/}
                        <CreatableSelect
                          isMulti
                          options={syn.synonyms.map(s => ({
                            value: s,
                            label: s
                          }))}
                          isDisabled={!syn.keyword}
                          value={syn.synonyms.map(s => ({
                            value: s,
                            label: s
                          }))}
                          onChange={e => synStore.handleSynonymChange(e, syn)}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <React.Fragment />
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <div align="center">
            <StyledButton
              size="medium"
              color="primary"
              variant="contained"
              onClick={e => synStore.addUnsavedRow(e)}
            >
              Add new mapping
            </StyledButton>
          </div>
          <TablePagination
            component="div"
            count={total ? total : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onPageChange={this._handleChangePage}
            onRowsPerPageChange={this._handleChangeRowsPerPage}
          />
        </Paper>
      </Container>
    );
  }
}

export default withAuth(
  inject("rootStore")(withStyles(styles)(observer(SynonymsTable))),
  SPORTY_ROLES
);
