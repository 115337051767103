import {createBrowserHistory} from "history";
import React, {Component} from "react";
import "./App.css";
import AppBar from "./components/AppBar";
import {ThemeProvider} from "styled-components";
import {Route, Router} from "react-router-dom";
import {Provider} from "mobx-react";
import RootStore from "./stores/rootStore";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Auth, ImplicitCallback, SecureRoute, Security} from "@okta/okta-react";

import {syncHistoryWithStore} from "mobx-react-router";

import AuthStore from "./stores/authStore";
import InvalidPermissions from "./components/InvalidPermissions";

import {ToastContainer} from "react-toastify";

const rootStore = new RootStore();

const theme = {
  lightGray: "rgb(240, 240, 240)",
  darkRed: "rgb(132, 0, 7)",
  brown: "rgb(130, 85, 8)",
  green: "rgb(17, 132, 44)",
  primary: "palevioletred"
};

const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#002D72"
    },
    secondary: {
      main: "#D50032"
    }
  }
});

const history = syncHistoryWithStore(
    createBrowserHistory(),
    rootStore.routerStore
);

const auth = new Auth({
  history,
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  client_id: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirect_uri: window.location.origin + "/implicit/callback"
});

const authStore = new AuthStore(auth);
authStore.getUser();

class App extends Component {
  render() {
    const {routerStore} = rootStore;

    return (
        <Provider rootStore={rootStore} authStore={authStore}>
          <Router history={routerStore.history}>
            <MuiThemeProvider theme={materialTheme}>
              <ThemeProvider theme={theme}>
                <Security auth={auth}>
                  <>
                    <CssBaseline/>
                    <AppBar rootStore={rootStore}/>
                    <ToastContainer/>
                    {routerStore.pages.map((page, key) => {
                      return (
                          <SecureRoute
                              key={key}
                              exact={page.isExact}
                              path={page.path}
                              component={page.component}
                          />
                      );
                    })}
                    <Route
                        path="/implicit/callback"
                        component={ImplicitCallback}
                    />
                    <Route
                        path="/invalidPermissions"
                        component={InvalidPermissions}
                    />
                  </>
                </Security>
              </ThemeProvider>
            </MuiThemeProvider>
          </Router>
        </Provider>
    );
  }
}

export default App;
