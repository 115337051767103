import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import MUISnackbar from "@material-ui/core/Snackbar";
import MUISnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";

const variantIcon = {
  success: CheckCircleIcon,
  warning: ReportProblemIcon,
  error: ErrorIcon
};

const snackbarContentStyles = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  warning: {
    backgroundColor: "#ffbb33"
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

const SnackbarContent = props => {
  const {
    actions,
    classes,
    className,
    message,
    onClose,
    variant,
    ...other
  } = props;
  const Icon = variantIcon[variant];
  const IconWithClasses = Icon ? (
    <Icon className={classNames(classes.icon, classes.iconVariant)} />
  ) : null;

  return (
    <MUISnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {IconWithClasses}
          {message}
        </span>
      }
      action={actions.concat(
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      )}
      {...other}
    />
  );
};

const SnackbarContentWrapper = withStyles(snackbarContentStyles)(
  SnackbarContent
);

class Snackbar extends React.Component {
  render() {
    const {
      actions,
      isOpen,
      message,
      variant,
      onCloseHandler,
      autoHideDuration
    } = this.props;

    return (
      <div>
        <MUISnackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={isOpen}
          autoHideDuration={autoHideDuration}
          onClose={onCloseHandler}
        >
          <SnackbarContentWrapper
            actions={actions ? actions : []}
            message={message}
            variant={variant}
            onClose={onCloseHandler}
          />
        </MUISnackbar>
      </div>
    );
  }
}

export default Snackbar;
