import _ from "lodash";
import { action, computed, decorate, observable, reaction } from "mobx";
import parsePitsNode from "../utils/parsePitsNode";
import Clause from "../model/Clause";
import { toast } from "react-toastify";

class MacroStore {
  macroId = null;
  text = "";
  clauses = [new Clause()];
  loading = false;
  saving = false;

  constructor(pitsApi, pitsMetadataStore, routerStore, macrosStore) {
    this.pitsApi = pitsApi;
    this.pitsMetadataStore = pitsMetadataStore;
    this.routerStore = routerStore;
    this.macrosStore = macrosStore;

    // metadata store is loaded async and we need it before we can load the macro
    reaction(
      () => this.pitsMetadataStore.isInitialized,
      () => this.loadMacro()
    );
    reaction(
      () => this.macroId,
      () => this.loadMacro()
    );
  }

  setMacro(macroId) {
    this.macroId = macroId;
  }

  setText(text) {
    this.text = text;
  }

  async loadMacro() {
    if (!this.macroId || !this.pitsMetadataStore.isInitialized) {
      return;
    }

    this.loading = true;
    const res = await this.pitsApi.getMacro(this.macroId);
    if (res.requestStatus.statusCode === 404) {
      toast.error(`Failed to find macro with id ${this.macroId}`, {
        position: "bottom-left"
      });
      this.reset();
      this.routerStore.history.push("/macros");
      return;
    }

    const macro = res.data[0];
    this.text = macro.phrase;

    const info = await this.pitsApi.getQueryInfo(macro.queryMapping);
    if (!info.data[0].valid) {
      console.log(`Query: ${macro.queryMapping} is not valid`);
      return;
    }

    const clauses = _.flattenDeep(
      _.map(info.data[0].graph.children, n =>
        parsePitsNode(n, this.pitsMetadataStore.fieldsWithDistinctValues)
      )
    );

    const flattenedClauses = _.flattenDeep(clauses);
    flattenedClauses.forEach(c => {
      c.type = this.pitsMetadataStore.getFieldInfo(c.fieldId).type;
    });

    this.clauses = flattenedClauses;
    this.loading = false;
  }

  addClause() {
    this.clauses[this.clauses.length - 1].logicalConjunctionOperator = "AND";
    this.clauses.push(new Clause());
  }

  removeClause(index) {
    this.clauses.splice(index, 1);
    this.clauses[this.clauses.length - 1].logicalConjunctionOperator = "";
  }

  previewMacro() {
    const url = `https://www.mlb.com/video/search?q=${encodeURI(
      this.query
    )}+Order+By+Timestamp+DESC`;
    window.open(url, "_blank");
  }

  async saveMacro() {
    this.saving = true;
    const macroRequest = { phrase: this.text, queryMapping: this.query };
    const res = this.macroId
      ? await this.pitsApi.updateMacro(this.macroId, macroRequest)
      : await this.pitsApi.insertMacro(macroRequest);
    if (res.requestStatus.statusCode <= 201) {
      this.routerStore.history.push("/macros");
      toast.success(`Macro saved: ${this.text}`, { position: "bottom-left" });
      this.reset();
    } else {
      toast.error(`Failed to save macro: ${res.requestStatus.msg}`, {
        position: "bottom-left"
      });
    }
    this.saving = false;
  }

  reset() {
    this.macroId = null;
    this.text = "";
    this.clauses = [new Clause()];
  }

  get query() {
    return this.clauses
      .map(c => c.query)
      .join(" ")
      .trim();
  }

  get isValid() {
    return this.text !== "" && _.every(this.clauses, c => c.isComplete);
  }
}

decorate(MacroStore, {
  setMacro: action,
  addClause: action,
  removeClause: action,
  previewMacro: action,
  reset: action,
  macroId: observable,
  text: observable,
  query: computed,
  isValid: computed,
  clauses: observable,
  loading: observable,
  saving: observable
});

export default MacroStore;
