import { action, decorate, observable } from "mobx";

class ClearCacheStore {
  constructor(jaredApi) {
    this.jaredApi = jaredApi;
    this.clearLoading = false;
    this.cacheTarget = "";
    this.cacheClearTypes = ["URL", "TAG"];
    this.selectedCacheClearType = "URL";
    this.selectedDomain = "";
    this.clearSuccess = false;
    this.responseMessage = "";
    this.responseVariant = "success";
    this.snackbarIsOpen = false;
    this.setZones();
  }

  setCacheTarget = cacheTarget => {
    this.cacheTarget = cacheTarget;
  };

  setSelectedCacheClearType = clearType => {
    this.selectedCacheClearType = clearType;
  };

  setSelectedDomain = domain => {
    this.selectedDomain = domain;
  };

  setZones = () => {
    this.zonesLoading = true;
    this.jaredApi.getCloudflareZones().then(d => {
      this.zones = d.zones;
      this.zonesLoading = false;
    });
  };

  submit = () => {
    this.clearLoading = true;
    this.snackbarIsOpen = false;
    if (this.selectedCacheClearType === "TAG") {
      if (!this.selectedDomain) {
        this.responseMessage = "Domain required for tag clear";
        this.responseVariant = "warning";
        this.snackbarIsOpen = true;
        this.clearLoading = false;
        return;
      }

      this.jaredApi
        // domain call required to be a valid url so add https here to the call
        .clearCacheByTag("https://" + this.selectedDomain, this.cacheTarget)
        .then(r => {
          this.clearLoading = false;
          this.clearSuccess = r.success;
          this.updateResponseMessage();
        });
    } else {
      this.jaredApi.clearCacheByUrl(this.cacheTarget).then(r => {
        this.clearLoading = false;
        this.clearSuccess = r.success;
        this.updateResponseMessage();
      });
    }
  };

  updateResponseMessage = () => {
    if (this.clearSuccess) {
      this.responseMessage =
        "Cache Clear Successful. Wait 30 seconds to retry URL";
      this.responseVariant = "success";
    } else {
      this.responseMessage = "Cache Clear Unsuccessful!";
      this.responseVariant = "error";
    }

    this.snackbarIsOpen = true;
  };

  closeSnackbar(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.responseVariant = "";
    this.responseMessage = "";
    this.snackbarIsOpen = false;
  }
}

decorate(ClearCacheStore, {
  clearLoading: observable,
  clearSuccess: observable,
  closeSnackbar: action,
  responseMessage: observable,
  responseVariant: observable,
  selectedCacheClearType: observable,
  setCacheTarget: action,
  selectedDomain: observable,
  setSelectedDomain: action,
  setZones: action,
  snackbarIsOpen: observable,
  zones: observable,
  zonesLoading: observable
});

export default ClearCacheStore;
