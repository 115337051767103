import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import { getPlayDoc } from "../httpClients/pitsApi";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { reasonDisplayName } from "../utils/displayNames";
import issueStatusOptions from "../constants/issueStatusOptions";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const columns = [
  { id: "game-pk", numeric: false, disablePadding: true, label: "Game PK" },
  { id: "play-id", numeric: false, disablePadding: true, label: "Play ID" },
  { id: "feed-type", numeric: false, disablePadding: true, label: "Feed" },
  {
    id: "comment",
    numeric: false,
    disablePadding: true,
    label: "Comment"
  },
  {
    id: "issue-category",
    numeric: false,
    disablePadding: true,
    label: "Reason"
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status"
  },
  {
    id: "created-at",
    numeric: false,
    disablePadding: true,
    label: "Created At"
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: ""
  }
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {columns.map(column => {
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? "none" : "default"}
                sortDirection={orderBy === column.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  }
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="title" id="tableTitle">
            Issues
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  table: {
    minWidth: 1020
  },
  tableWrapper: {
    overflowX: "auto"
  },
  select: {
    fontSize: "0.8125rem"
  }
});

class EnhancedTable extends React.Component {
  componentDidMount() {
    this.props.rootStore.issuesStore.getIssues();
  }

  render() {
    const { classes, columns, rootStore } = this.props;
    const { clippyApi, issuesStore, videoPlayerStore } = rootStore;
    const {
      rowsPerPage,
      selected,
      order,
      orderBy,
      page,
      issues: data,
      totalIssues
    } = issuesStore;
    const emptyRows = 0;

    return (
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          store={issuesStore}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              columns={columns}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={(e, checked) =>
                issuesStore.handleSelectAll(e, checked)
              }
              onRequestSort={(event, property) =>
                issuesStore.handleSortRequest(event, property)
              }
              rowCount={data.length}
            />
            <TableBody>
              {data.map(i => {
                const issue = observable(i);
                const isSelected = issuesStore.isSelected(issue.id);
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={issue.id}
                    selected={isSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isSelected}
                        onClick={e => e.stopPropagation()}
                        onChange={(e, change) =>
                          issuesStore.handleSelect(issue.id)
                        }
                      />
                    </TableCell>
                    <TableCell padding="none">{issue.gamePk}</TableCell>
                    <TableCell padding="none">{issue.playId}</TableCell>
                    <TableCell padding="none">{issue.feedType}</TableCell>
                    <TableCell padding="none">{issue.comment}</TableCell>
                    <TableCell padding="none">
                      {reasonDisplayName(issue.issueType)}
                    </TableCell>
                    <TableCell padding="none">
                      <Select
                        className={classes.select}
                        onChange={e => {
                          issuesStore
                            .putIssue({ ...issue, status: e.target.value })
                            .then(() => (issue.status = e.target.value));
                        }}
                        value={issue.status}
                      >
                        {issueStatusOptions.map((opt, i) => (
                          <MenuItem key={i} value={opt.value}>
                            {opt.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell padding="none">
                      {moment(issue.createdAt).format("MMM D, YYYY")}
                    </TableCell>
                    <TableCell padding="none">
                      <Button
                        size="small"
                        color="primary"
                        onClick={e =>
                          clippyApi
                            .getVideoUrl(
                              issue.gamePk,
                              issue.playId,
                              issue.feedType
                            )
                            .then(d =>
                              videoPlayerStore.showVideoSrc(d.data[0].link)
                            )
                        }
                      >
                        Watch
                      </Button>
                      <Button
                        size="small"
                        color="primary"
                        href={getPlayDoc(issue.playId)}
                        target="_blank"
                      >
                        Search Document
                      </Button>
                      <Button
                        size="small"
                        color="primary"
                        component={props => (
                          <Link
                            to={`issues/${issue.id}/adjustVideo`}
                            {...props}
                          />
                        )}
                      >
                        Adjust Video
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={totalIssues}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 20, 30]}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={(event, page) => issuesStore.setPage(page)}
          onChangeRowsPerPage={event =>
            issuesStore.setRowsPerPage(event.target.value)
          }
        />
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default inject("rootStore")(withStyles(styles)(observer(EnhancedTable)));
