import { action, decorate, observable } from "mobx";

class VideoPlayerStore {
  isOpen = false;
  videoSrc = "";

  hidePlayer() {
    this.isOpen = false;
    this.videoSrc = "";
  }

  showVideoSrc(link) {
    this.videoSrc = link;
    this.isOpen = true;
  }
}

decorate(VideoPlayerStore, {
  isOpen: observable,
  videoSrc: observable,
  showVideoSrc: action
});

export default VideoPlayerStore;
