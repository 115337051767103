import axios from "axios";
import moment from "moment";
import _ from "lodash";

const BASE_URL = "https://statsapi.mlb.com/api/v1";

export default class StatsApi {
  constructor() {
    this.gameStates = [];
    this.loadGameStates().then(s => (this.gameStates = s));
  }

  loadGameStates = () => {
    return axios.get(`${BASE_URL}/gameStatus`).then(r => r.data);
  };

  hasGameStarted(statusCode) {
    const state = this.getGameState(statusCode.toUpperCase());
    if (state) {
      return _.includes(["Final", "Live"], state);
    }

    return false;
  }

  getGameState(statusCode) {
    const state = _.find(this.gameStates, s => s.statusCode === statusCode);

    if (state) {
      return state.abstractGameState;
    }

    return "";
  }

  getSchedule(date) {
    return axios
      .get(`${BASE_URL}/schedule?sportId=1&date=${date}&hydrate=team`)
      .then(r => {
        return _.find(r.data.dates, scheduleDate =>
          moment(date).isSame(moment(scheduleDate.date))
        );
      });
  }

  getDefaultScheduleDate() {
    return axios
      .get(`${BASE_URL}/schedule?sportId=1`)
      .then(r => (r.data.dates.length ? r.data.dates[0] : {}));
  }

  getGamePkDetails(gamePk) {
    return axios
      .get(
        `${BASE_URL}/schedule?gamePk=${gamePk}&useLatestGames=true&hydrate=team`
      )
      .then(r => {
        return r.data;
      });
  }

  getTeamsData() {
    return axios
      .get(
        `${BASE_URL}/teams?sportIds=1,11,12,13,14,16,17,22&hydrate=venue(xrefId)`
      )
      .then(r => {
        return r.data;
      });
  }
}
