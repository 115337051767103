import _ from "lodash";
import { computed, decorate, observable } from "mobx";

function formatValue(value, quoted) {
  const formattedVal = typeof value === "string" ? value.trim() : value;
  if (quoted) {
    return `"${formattedVal}"`;
  }

  return formattedVal;
}

class Clause {
  defaults = {
    emptyList: [],
    emptyString: "",
    unsetNumber: -1
  };
  category = this.defaults["emptyString"];
  fieldId = this.defaults["unsetNumber"];
  fieldName = this.defaults["emptyString"];
  operator = this.defaults["emptyString"];
  type = this.defaults["emptyString"];
  value = this.defaults["emptyString"];
  values = this.defaults["emptyList"];
  logicalConjunctionOperator = this.defaults["emptyString"];

  setCategory(category) {
    if (this.category === category) {
      return;
    }

    this.resetFieldData();
    this.resetType();
    this.resetOperator();
    this.resetValue();
    this.resetValues();
    this.category = category;
  }

  setField(field) {
    if (this.fieldId === field.id) {
      return;
    }

    this.resetOperator();
    this.resetValue();
    this.resetValues();

    this.fieldId = field.id;
    this.fieldName = field.name;
    this.type = field.type;
    this.operator = field.defaultOperator;
  }

  resetFieldData() {
    this.fieldId = this.defaults["unsetNumber"];
    this.fieldName = this.defaults["emptyString"];
  }

  resetOperator() {
    this.operator = this.defaults["emptyString"];
  }

  resetType() {
    this.type = this.defaults["emptyString"];
  }

  resetValue() {
    this.value = this.defaults["emptyString"];
  }

  resetValues() {
    this.values = this.defaults["emptyList"];
  }

  get isFieldNameDisabled() {
    return this.category === "";
  }

  get isOperatorDisabled() {
    return this.fieldId === -1;
  }

  get isComplete() {
    return (
      this.fieldId !== -1 &&
      this.operator !== "" &&
      (this.value !== "" || this.values.length > 0)
    );
  }

  stringifyPitsValue(property) {
    let quoted = true;
    if (this.type === "NUMBER") {
      quoted = false;
    }

    // Gather the values we need to stringify
    // Property type of Value is free text,
    // Values is selections from react select (aka predefined values)
    let valuesToStringify;
    if (property === "value") {
      valuesToStringify = [this.value];
    } else {
      valuesToStringify = this.values.map(v =>
        typeof v === "object" ? v.value : v
      );
    }

    // If just a single clause, trim and return
    if (valuesToStringify.length === 1) {
      return formatValue(valuesToStringify[0], quoted);
    }

    return `[${valuesToStringify.map(v => formatValue(v, quoted))}]`;
  }

  get query() {
    const value = this.stringifyPitsValue(
      _.isEmpty(this.values) ? "value" : "values"
    );
    return `${this.fieldName} ${this.operator} ${value} ${this.logicalConjunctionOperator}`;
  }
}

decorate(Clause, {
  category: observable,
  fieldId: observable,
  fieldName: observable,
  operator: observable,
  type: observable,
  value: observable,
  values: observable,
  query: computed,
  logicalConjunctionOperator: observable,
  isFieldNameDisabled: computed,
  isOperatorDisabled: computed,
  isComplete: computed
});

export default Clause;
