export const USER_ROLES = {
  SPORTY_ADMIN: "Sportybot Admins",
  OPERATIONAL_ADMIN: "Sportybot operational admin",
  OPERATIONAL_VIEWER: "Sportybot operational Viewer"
};

export const ALL_VALID_ROLES = [
  USER_ROLES.SPORTY_ADMIN,
  USER_ROLES.OPERATIONAL_ADMIN,
  USER_ROLES.OPERATIONAL_VIEWER
];

export const SPORTY_ROLES = [USER_ROLES.SPORTY_ADMIN];
