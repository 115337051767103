import { decorate, observable, action } from "mobx";
import _ from "lodash";

class AuthStore {
  isAuthenticated = null;
  isGettingUser = false;
  user = {};

  constructor(auth) {
    this.auth = auth;
  }

  getUser = async () => {
    this.isGettingUser = true;
    try {
      await this.checkAuthentication();
      if (this.isAuthenticated) {
        this.user = await this.auth.getUser();
      }
      this.isGettingUser = false;
    } catch (e) {
      //Something went wrong, clear out localStorage to make the user login again
      window.localStorage.clear();
      window.location.reload();
    }
  };

  checkAuthentication = async () => {
    //isAuthenticated only requires an accessToken, we need the idToken for getting the user's information
    let idToken = await this.auth._oktaAuth.tokenManager.get("idToken");
    while (idToken === undefined) {
      idToken = await this.auth._oktaAuth.tokenManager.get("idToken");
    }
    const isAuthenticated =
      (await this.auth.isAuthenticated()) && idToken !== undefined;
    if (isAuthenticated !== this.isAuthenticated) {
      this.isAuthenticated = isAuthenticated;
    }
  };

  onSuccess = async res => {
    if (res.status === "SUCCESS") {
      /**
       * this.auth.redirect sets the tokens in localStorage.
       * However the timing is unpredictable and can be too slow for initial api requests
       * getWithoutPrompt allows us to guarantee that the tokens are available on initial login.
       */
      const tokens = await this.auth._oktaAuth.token.getWithoutPrompt({
        sessionToken: res.session.token,
        responseType: ["token", "id_token"],
        scopes: ["openid", "email", "profile"]
      });
      const [accessToken, idToken] = tokens;
      const tokenString = JSON.stringify({ accessToken, idToken });
      window.localStorage.setItem("okta-token-storage", tokenString);
      return this.auth.redirect({
        sessionToken: res.session.token
      });
    } else {
      // The user can be in another authentication state that requires further action.
      // For more information about these states, see:
      //   https://github.com/okta/okta-signin-widget#rendereloptions-success-error
    }
  };

  onError = err => {
    console.log("error logging in", err);
  };

  logout = () => {
    this.isAuthenticated = false;
    this.auth.logout("/login");
  };

  checkPermission(requestedPermission) {
    return _.intersection(this.user.groups, requestedPermission).length > 0;
  }
}

decorate(AuthStore, {
  isAuthenticated: observable,
  user: observable,
  isGettingUser: observable,
  getUser: action
});

export default AuthStore;
