import { action, computed, decorate, observable, reaction } from "mobx";
import { toast } from "react-toastify";
import dayjs from "dayjs";

function convertMsToTime(milliseconds) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  milliseconds = milliseconds % 1000;
  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;

  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s ${milliseconds}ms`;
  } else if (minutes > 0) {
    return `${minutes}m ${seconds}s ${milliseconds}ms`;
  } else if (seconds > 0) {
    return `${seconds}s ${milliseconds}ms`;
  }

  return `${milliseconds}ms`;
}

class PlayAuditStore {
  constructor(sportyBookApi, pitsApi, clippyApi, jaredApi) {
    this.sportyBookApi = sportyBookApi;
    this.pitsApi = pitsApi;
    this.clippyApi = clippyApi;
    this.jaredApi = jaredApi;

    this.playId = "";
    this.gamePk = "";
    this.startTime = "";
    this.endTime = "";
    this.pitchTime = "";

    this.playLoading = false;
    this.videosLoading = false;
    this.eventsLoading = false;
    this.documentsLoading = false;
    this.reindexing = false;

    this.auditDocuments = [];
    this.auditTimes = [];
    this.videos = [];

    this.spanIdFilter = "";
    this.eventTypeFilter = "";
    this.currentPage = 0;
    this.rowsPerPage = 25;
    this.totalRows = 0;

    reaction(() => this.playId, () => this.fetchPlayAudit());
    reaction(() => this.eventTypeFilter, () => this.fetchEventTimes());
    reaction(() => this.spanIdFilter, () => this.fetchEventTimes());
    reaction(() => this.currentPage, () => this.fetchEventTimes());
    reaction(() => this.rowsPerPage, () => this.fetchEventTimes());
  }

  fetchPlayAudit() {
    if (this.playId !== "") {
      this.playLoading = true;
      this.pitsApi
        .getPlayInfo(this.playId)
        .then(d => {
          this.startTime = d["StartTime"];
          this.pitchTime = d["PitchTime"];
          this.endTime = d["EndTime"];
          this.gamePk = d["GamePk"];

          this.videosLoading = true;
          this.clippyApi
            .getVideos(this.gamePk, this.playId)
            .then(v => {
              this.videos = v.data;
              if (this.videos && this.videos.length) {
                this.selectedFeedVideo = this.videos[0];
                this.selectedFeedVideoIndex = 0;
              }
            })
            .finally(() => {
              this.videosLoading = false;
            });

          // All the documents come back in one call, event times are paginated though
          this.fetchEventTimes();

          this.documentsLoading = true;
          this.sportyBookApi
            .getPlayDocuments(this.gamePk, this.playId)
            .then(p => {
              this.auditDocuments = p.data;
            })
            .finally(() => {
              this.documentsLoading = false;
            });
        })
        .finally(() => {
          this.playLoading = false;
        });
    }
  }

  fetchEventTimes() {
    this.eventsLoading = true;
    this.sportyBookApi
      .getPlayEvents(
        this.gamePk,
        this.playId,
        this.spanIdFilter,
        this.eventTypeFilter,
        this.rowsPerPage,
        this.currentPage
      )
      .then(p => {
        this.auditTimes = p.data;
        this.totalRows = p.total;
      })
      .finally(() => {
        this.eventsLoading = false;
      });
  }

  setPlayId(playId) {
    if (playId && playId.length === 36) {
      this.playId = playId;
    }
  }

  setSpanIdFilter(spanId) {
    this.currentPage = 0;
    this.spanIdFilter = spanId;
  }

  setEventTypeFilter(eventType) {
    this.currentPage = 0;
    this.eventTypeFilter = eventType;
  }

  async reindexPlay() {
    this.reindexing = true;
    const res = await this.jaredApi.reindexPlay(this.gamePk, this.playId);
    if (!res.success) {
      toast.error("Failed to reindex play", { position: "bottom-left" });
    } else {
      toast.success("Sent request to reindex play", {
        position: "bottom-left"
      });
    }
    this.reindexing = true;
  }

  get loading() {
    return (
      this.playLoading ||
      this.videosLoading ||
      this.eventsLoading ||
      this.documentsLoading
    );
  }

  get auditEvents() {
    if (this.auditTimes.length === 0) {
      return [];
    }

    const firstEventTime = dayjs(this.auditTimes[0].eventTime);
    return this.auditTimes.map(auditTime => {
      const modified = JSON.parse(JSON.stringify(auditTime));
      // find the matching document for this time (if applicable)
      modified.document = this.auditDocuments.find(
        doc => doc.url && doc.url.includes(auditTime.eventTime)
      );

      const auditEventTime = dayjs(auditTime.eventTime);
      modified.eventTimeFormatted = auditEventTime.format(
        "MMM D h:mm:ss.SSS a"
      );
      if (this.spanIdFilter) {
        modified.timeDiff = convertMsToTime(
          auditEventTime.diff(firstEventTime, "ms")
        );
      }
      return modified;
    });
  }

  get actionsEnabled() {
    return this.playId && this.gamePk;
  }
}

decorate(PlayAuditStore, {
  playId: observable,
  loading: computed,
  playLoading: observable,
  videosLoading: observable,
  eventsLoading: observable,
  documentsLoading: observable,
  currentPage: observable,
  totalRows: observable,
  rowsPerPage: observable,
  auditDetails: observable,
  auditTimes: observable,
  auditDocuments: observable,
  auditEvents: computed,
  actionsEnabled: computed,
  gamePk: observable,
  pitchTime: observable,
  startTime: observable,
  endTime: observable,
  videos: observable,
  setPlayId: action,
  setSpanIdFilter: action,
  setEventTypeFilter: action,
  spanIdFilter: observable,
  eventTypeFilter: observable,
  reindexing: observable,
  reindexPlay: action
});

export default PlayAuditStore;
