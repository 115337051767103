import React from "react";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import withAuth from "../containers/withAuth";
import { ALL_VALID_ROLES } from "../constants/authConstants";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsTimeline from "highcharts/modules/timeline";
import Grid from "@material-ui/core/Grid";
import ReactJson from "react-json-view";
import SearchBar from "material-ui-search-bar";

import {
  Backdrop,
  CircularProgress,
  TableContainer,
  TextField
} from "@material-ui/core";
import { debounce } from "lodash";

// init the module
highchartsTimeline(Highcharts);

const STATUS_COLORS = {
  SUCCESS: "green",
  CANCELLED: "orange",
  FAILURE: "red",
  RUNNING: "blue",
  SCHEDULED: "blue",
  QUEUED: "gray"
};

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: 150
  }
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 50px;
`;

const ContainerItem = styled.div`
  margin-top: 20px;
`;

function getColorForAudit(status) {
  if (status) {
    return STATUS_COLORS[status] || "black";
  }

  return "black";
}

class GameAudit extends React.Component {
  componentDidMount() {
    this.loadGame();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.loadGame();
  }

  loadGame() {
    const { gameAuditStore } = this.props.rootStore;
    const params = new URLSearchParams(this.props.location.search);
    if (
      params.get("gamePk") &&
      params.get("gamePk") !== gameAuditStore.gamePk
    ) {
      gameAuditStore.setGame(params.get("gamePk"));
    }
  }

  getLabelForAudit(audit) {
    if (audit.type === "EVENT") {
      if (audit.subType.startsWith("INNING")) {
        return `${audit.subType} (${audit.data["inning"]})`;
      }
    }

    return audit.subType;
  }

  getChartConfig(audits) {
    return {
      chart: {
        zoomType: "x",
        type: "timeline",
        inverted: true,
        height: audits.length * 50,
        backgroundColor: null
      },
      xAxis: {
        type: "datetime",
        visible: false
      },
      yAxis: {
        gridLineWidth: 1,
        title: null,
        labels: {
          enabled: false
        }
      },
      title: {
        text: null
      },
      subtitle: {
        text: ""
      },
      tooltip: {
        enabled: true,
        formatter: function() {
          return `${this.point.name}</br><span style="color: ${this.point.color}">${this.point.label}</span>`;
        }
      },
      series: [
        {
          dataLabels: {
            allowOverlap: false,
            format:
              '{point.name}</br><span style="color: {point.color}">{point.label}</span>'
          },
          data: audits.map(audit => {
            return {
              x: audit.time.valueOf(), // this will automatically space the events out but causes overlap
              name: audit.time.format("MMM D HH:mm:ss"),
              label: this.getLabelForAudit(audit),
              description: undefined,
              marker: {
                symbol: "circle"
              },
              color: getColorForAudit(audit.data["status"]),
              dataLabels: {
                borderColor: getColorForAudit(audit.data["status"]),
                borderWidth: 2,
                connectorWidth: 2,
                connectorColor: getColorForAudit(audit.data["status"])
              }
            };
          })
        }
      ]
    };
  }

  render() {
    const {
      rootStore: { gameAuditStore, routerStore }
    } = this.props;

    const config = this.getChartConfig(gameAuditStore.audits);
    return (
      <Container>
        <div>
          <ContainerItem id="game-audit-info">
            <Grid container spacing={4}>
              <Grid
                item
                xs={3}
                style={{ maxHeight: "90vh", overflowY: "scroll" }}
              >
                <SearchBar
                  value={gameAuditStore.gamePk}
                  placeholder={"Game PK"}
                  style={{ marginTop: "6px" }}
                  onChange={debounce(
                    // value => gameAuditStore.setGame(value),
                    value =>
                      routerStore.history.push(`/game-audit?gamePk=${value}`),
                    500
                  )}
                  onCancelSearch={() => gameAuditStore.setGame("")}
                />
                <TextField
                  margin="dense"
                  id="gameTime"
                  label="Game Time"
                  type="text"
                  fullWidth
                  disabled
                  value={gameAuditStore.gameDetails.gameTime}
                />
                <TextField
                  margin="dense"
                  id="matchup"
                  label="Matchup"
                  type="text"
                  fullWidth
                  disabled
                  value={gameAuditStore.gameDetails.matchup}
                />
                <TextField
                  margin="dense"
                  id="status"
                  label="Status"
                  type="text"
                  fullWidth
                  disabled
                  value={gameAuditStore.gameDetails.status}
                  style={{ marginBottom: "1rem" }}
                />
                {gameAuditStore.gamePk && (
                  <HighchartsReact highcharts={Highcharts} options={config} />
                )}
              </Grid>
              {gameAuditStore.gamePk && (
                <Grid
                  item
                  xs={9}
                  style={{ maxHeight: "90vh", overflowY: "scroll" }}
                >
                  <TableContainer>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Time</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>SubType</TableCell>
                          <TableCell>Data</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {gameAuditStore.audits.map(audit => {
                          return (
                            <TableRow key={audit.time.toISOString()}>
                              <TableCell style={{ width: "20%" }}>
                                {audit.time.toISOString()}
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "10%",
                                  color: getColorForAudit(audit.data["status"])
                                }}
                              >
                                {audit.type}
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "20%",
                                  color: getColorForAudit(audit.data["status"])
                                }}
                              >
                                {this.getLabelForAudit(audit)}
                              </TableCell>
                              <TableCell>
                                <ReactJson
                                  src={audit.data}
                                  collapsed={true}
                                  sortKeys={true}
                                  enableClipboard={false}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
            </Grid>
          </ContainerItem>
        </div>
        <Backdrop
          style={{ color: "#fff", zIndex: 999 }}
          open={gameAuditStore.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

export default withAuth(
  inject("rootStore")(withStyles(styles)(observer(GameAudit))),
  ALL_VALID_ROLES
);
