import _ from "lodash";
import Clause from "../model/Clause";

function parseListNode(node, fieldsWithDistinctValues) {
  const clauses = _.map(node.children, n =>
    parsePitsNode(n, fieldsWithDistinctValues)
  );
  return _.reduce(
    _.tail(clauses),
    (acc, c) => {
      if (fieldsWithDistinctValues.includes(c.fieldName)) {
        acc.values = acc.values.concat(c.values);
      } else {
        acc.value += `,${c.value}`;
      }
      return acc;
    },
    _.head(clauses)
  );
}

function parseRangeNode(node, fieldsWithDistinctValues) {
  const clauses = _.map(node.children, n =>
    parsePitsNode(n, fieldsWithDistinctValues)
  );

  clauses[0].logicalConjunctionOperator = "AND";

  return clauses;
}

function parseLogicalOperatorNode(node, fieldsWithDistinctValues) {
  const clauses = _.map(node.children, n =>
    parsePitsNode(n, fieldsWithDistinctValues)
  );
  const [first] = clauses;
  if (Array.isArray(first)) {
    _.last(first).logicalConjunctionOperator = node.name;
  } else {
    first.logicalConjunctionOperator = node.name;
  }
  return clauses;
}

function parseQueryNode(node, fieldsWithDistinctValue) {
  const clause = new Clause();
  clause.category = node.category;
  clause.fieldId = node.fieldId;
  clause.fieldName = node.name;
  clause.operator = node.comparator;
  if (fieldsWithDistinctValue.includes(node.name)) {
    clause.values = [{ label: node.displayValue, value: node.value }];
  } else {
    clause.value = node.value;
  }
  return clause;
}

function parsePitsNode(node, fieldsWithDistinctValues) {
  switch (node.nodeType) {
    case "LIST":
      return parseListNode(node, fieldsWithDistinctValues);
    case "LOGICAL_OPERATOR":
      return parseLogicalOperatorNode(node, fieldsWithDistinctValues);
    case "QUERY":
      return parseQueryNode(node, fieldsWithDistinctValues);
    case "RANGE":
      return parseRangeNode(node, fieldsWithDistinctValues);
    default:
      return [];
  }
}

export default parsePitsNode;
