import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import _ from "lodash";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import FeedRow from "./FeedRow";

const styles = theme => ({
  root: {
    width: "100%"
  },
  column: {
    flexBasis: "33.33%"
  },
  noBorder: {
    border: "0"
  }
});

const columns = [
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  { id: "feed-type", numeric: false, disablePadding: false, label: "Feed" },
  { id: "videos", numeric: true, disablePadding: false, label: "Videos Cut" },
  { id: "plays", numeric: true, disablePadding: false, label: "Plays Listed" },
  { id: "missing", numeric: true, disablePadding: false, label: "Missing" },
  { id: "Resend", numeric: false, disablePadding: false, label: "Resend" }
];

class GameDetails extends Component {
  componentDidMount() {
    const { videoDiagnosticStore: store } = this.props.rootStore;

    const { gamePk } = this.props;
    store.fetchGameValidationDetails(gamePk);
  }

  render() {
    const { classes, gamePk } = this.props;

    const { videoDiagnosticStore: store } = this.props.rootStore;

    const feedDetails = _.find(store.gameCache, g => g.gamePk === gamePk);

    return (
      <div>
        <Table className={classes.table}>
          {feedDetails && feedDetails.feeds.length > 0 ? (
            <TableHead>
              <TableRow>
                {columns.map(column => {
                  return (
                    <TableCell
                      key={column.id}
                      numeric={column.numeric}
                      padding={column.disablePadding ? "none" : "default"}
                    >
                      {column.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : (
            <React.Fragment />
          )}

          <TableBody>
            {feedDetails ? (
              feedDetails.feeds.length === 0 ? (
                <TableRow>
                  <TableCell className={classes.noBorder}>No Feeds!</TableCell>
                </TableRow>
              ) : (
                feedDetails.feeds.map(f => {
                  return (
                    <FeedRow
                      key={f.feed + "-" + gamePk}
                      feedDetails={f}
                      feedType={f.feed}
                      playCount={feedDetails.playCount}
                      gamePk={gamePk}
                    />
                  );
                })
              )
            ) : (
              <React.Fragment />
            )}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default inject("rootStore")(withStyles(styles)(observer(GameDetails)));
