const BASE_URL = process.env.REACT_APP_SPORTYWEB_BASE_URL;

class SportyWebApi {}
// This looks ugly but as far as I know JS doesn't support static data properties
// Source: http://exploringjs.com/es6/ch_classes.html#_static-data-properties
SportyWebApi.BASE_URL = BASE_URL;

export default SportyWebApi;

export const getSportyUrl = query => {
  return encodeURI(`${BASE_URL}?q=${query}`);
};

export const getRuleUrl = ruleId => {
  return encodeURI(`${BASE_URL}/rules/${ruleId}/edit`);
};
