import { inject, observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import AssetOverrideRow from "./AssetOverrideRow";
import AssetOverrideDialog from "./AssetOverrideDialog";
import { ALL_VALID_ROLES } from "../../constants/authConstants";
import withAuth from "../../containers/withAuth";
import DatePicker from "react-datepicker";
import rem from "../../utils/rem";
import moment from "moment";
import Box from "@material-ui/core/Box";
import { Backdrop, CircularProgress } from "@material-ui/core";

const Container = styled.div`
  margin-top: 20px;
  padding: 0 50px;
`;

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  button: {
    marginLeft: 20
  },
  p: {
    marginLeft: 20
  }
});

const DateInput = styled.input`
  text-align: center;
  line-height: ${rem(16)};
  border-radius: ${rem(4)};
  border: 1px solid #aeaeae;
  font-size: ${rem(13)};
  padding: ${rem(6)} ${rem(10)} ${rem(6)};
  cursor: pointer;
`;

class VideoAssetOverrides extends React.Component {
  componentDidMount() {
    const { videoAssetOverridesStore: store } = this.props.rootStore;
    store.loadOverrides();
  }

  _handleDateChange(date, mode) {
    const { videoAssetOverridesStore: store } = this.props.rootStore;
    const formattedDate = date ? date.format("YYYY-MM-DD") : "";
    if (mode === "start") {
      store.startDate = formattedDate;
    } else {
      store.endDate = formattedDate;
    }
  }

  render() {
    const { classes } = this.props;
    const { videoAssetOverridesStore: store } = this.props.rootStore;
    return (
      <Container>
        <Box sx={{ display: "inline-flex" }}>
          <span style={{ marginRight: ".5rem", marginTop: ".5rem" }}>
            {" "}
            From:{" "}
          </span>
          <DatePicker
            selected={new moment(store.startDate)}
            onChange={date => this._handleDateChange(date, "start")}
            dateFormat="LL"
            customInput={<DateInput />}
          />
          <span style={{ margin: ".5rem" }}> To: </span>
          <DatePicker
            selected={new moment(store.endDate)}
            onChange={date => this._handleDateChange(date, "end")}
            dateFormat="LL"
            customInput={<DateInput />}
          />
        </Box>
        <AssetOverrideDialog />
        <div>
          <Paper className={classes.root}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Game PK</TableCell>
                  <TableCell>Feed Type</TableCell>
                  <TableCell>Asset Override Names</TableCell>
                  <TableCell>Game Date</TableCell>
                  <TableCell>From (UTC)</TableCell>
                  <TableCell>To (UTC)</TableCell>
                  <TableCell>Timecode Offset</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {store.overrides.length > 0 ? (
                  store.overrides.map(o => {
                    return (
                      <AssetOverrideRow
                        key={`${o.id}`}
                        id={`${o.id}`}
                        override={o.override}
                        gamePk={o.gamePk}
                        gameDate={o.gameDate}
                        from={o.fromTS}
                        to={o.toTS}
                        feedType={o.feedType}
                        timecodeOffsetOverride={o.timecodeOffsetOverride}
                      />
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell className={classes.noBorder}>
                      No overrides found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </div>
        <Backdrop style={{ color: "#fff", zIndex: 999 }} open={store.isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

export default withAuth(
  inject("rootStore")(withStyles(styles)(observer(VideoAssetOverrides))),
  ALL_VALID_ROLES
);
