import { action, computed, decorate, observable, reaction } from "mobx";
import dayjs from "dayjs";

class GameAuditStore {
  constructor(bivoApi, sportyBookApi, statsApi) {
    this.gamePk = "";

    this.gameLoading = false;
    this.eventsLoading = false;
    this.jobsLoading = false;

    this.scheduleEvents = [];
    this.jobs = [];
    this.audits = [];
    this.gameDetails = { gameTime: "", matchup: "", status: "" };

    this.bivoApi = bivoApi;
    this.sportyBookApi = sportyBookApi;
    this.statsApi = statsApi;

    reaction(
      () => this.gamePk,
      () => this.fetchData()
    );
  }

  setGame(gamePk) {
    if (gamePk > 0) {
      this.gamePk = gamePk;
    } else {
      this.gamePk = "";
      this.scheduleEvents = [];
      this.jobs = [];
      this.audits = [];
      this.gameDetails = { gameTime: "", matchup: "", status: "" };
    }
  }

  fetchData() {
    if (!this.gamePk || this.gamePk < 0) {
      return;
    }

    this.gameLoading = true;
    this.statsApi
      .getGamePkDetails(this.gamePk)
      .then(details => {
        if (details.dates.length === 1 && details.dates[0].games.length >= 1) {
          const game = details.dates[0].games[0];
          this.gameDetails = {
            gameTime: dayjs(game.gameDate).format("YYYY-MM-DD h:mm:ss a (Z)"),
            matchup: `${game.teams.away.team.abbreviation} @ ${game.teams.home.team.abbreviation}`,
            status: game.status.detailedState
          };
        }
      })
      .finally(() => {
        this.gameLoading = false;
      });

    this.eventsLoading = true;
    // Get all the schedule events in one go
    this.sportyBookApi
      .getScheduleEvents(this.gamePk, 50, 0)
      .then(response => {
        this.scheduleEvents = response["data"].map(event => {
          return {
            type: "EVENT",
            subType: event["eventData"]["eventType"],
            time: dayjs(event["eventTime"]),
            data: event["eventData"]
          };
        });
        this.sortAll();
      })
      .finally(() => {
        this.eventsLoading = false;
      });

    this.jobsLoading = true;
    this.bivoApi
      .getJobs(this.gamePk)
      .then(response => {
        this.jobs = response["jobs"].map(job => {
          return {
            type: "JOB",
            subType: job["executor_params"]["action"],
            time: job["execute_time"]
              ? dayjs(job["execute_time"])
              : dayjs(job["run_after"]),
            data: job
          };
        });
        this.sortAll();
      })
      .finally(() => {
        this.jobsLoading = false;
      });
  }

  sortAll() {
    this.audits = this.scheduleEvents
      .concat(this.jobs)
      .sort((a, b) => (a.time.isAfter(b.time) ? 1 : -1));
  }

  get loading() {
    return this.jobsLoading || this.eventsLoading || this.gameLoading;
  }
}

decorate(GameAuditStore, {
  loading: computed,
  gamePk: observable,
  setGame: action,
  gameDetails: observable,
  scheduleEvents: observable,
  jobs: observable,
  audits: observable,
  gameLoading: observable,
  jobsLoading: observable,
  eventsLoading: observable
});

export default GameAuditStore;
