import React from "react";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

class AppBarMenu extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { component, items, label } = this.props;

    return (
      <>
        <Button
          aria-controls={label}
          aria-haspopup="true"
          onClick={this.handleClick}
          size="small"
          endIcon={<ExpandMoreIcon />}
          component={component}
        >
          {label}
        </Button>
        <Menu
          id={label}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {items.map(({ label, ...otherProps }, idx) => (
            <MenuItem key={idx} onClick={this.handleClose} {...otherProps}>
              {label}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

export default AppBarMenu;
