import { action, decorate, observable } from "mobx";

class SearchSuggestionsStore {
  constructor(pitsApi) {
    this.pitsApi = pitsApi;
    this.pinnedSuggestions = [];
    this.randomizedSuggestions = [];
  }
  snackbarIsOpen = false;
  snackbarMessage = "";
  snackbarVariant = "";

  getPinnedSearchSuggestions() {
    return this.pitsApi.getPinnedSearchSuggestions().then(r => {
      if (r.data) {
        this.pinnedSuggestions = r.data;
      }
    });
  }

  getRandomizedSearchSuggestions() {
    return this.pitsApi.getRandomizedSearchSuggestions().then(r => {
      if (r.data) {
        this.randomizedSuggestions = r.data;
      }
    });
  }

  addPinnedSearchSuggestion(suggestedSearch, sortOrder) {
    return this.pitsApi
      .addPinnedSearchSuggestion(suggestedSearch, sortOrder)
      .then(r => {
        this.getPinnedSearchSuggestions();
        if (!r.data[0]) {
          throw {
            message: "Oops, looks like something went wrong"
          };
        }
      })
      .catch(e => {
        this.showSnackbar("error", e.message);
      });
  }

  addRandomizedSearchSuggestion(suggestedSearch, sortOrder) {
    return this.pitsApi
      .addRandomizedSearchSuggestion(suggestedSearch, sortOrder)
      .then(r => {
        if (r.data[0]) {
          this.getRandomizedSearchSuggestions();
        }
      });
  }

  deleteSearchSuggestion(id) {
    return this.pitsApi.deleteSearchSuggestion(id).then(r => {
      if (r.data.data[0]) {
        this.getPinnedSearchSuggestions();
        this.getRandomizedSearchSuggestions();
      }
    });
  }

  reorderSearchSuggestions(reorderList) {
    return this.pitsApi.reorderSearchSuggestions(reorderList).then(r => {
      if (r.data[0]) {
        this.getPinnedSearchSuggestions();
      }
    });
  }

  showSnackbar(variant, message) {
    this.snackbarVariant = variant;
    this.snackbarMessage = message;
    this.snackbarIsOpen = true;
  }

  closeSnackbar(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.snackbarVariant = "";
    this.snackbarMessage = "";
    this.snackbarIsOpen = false;
  }
}

decorate(SearchSuggestionsStore, {
  getPinnedSearchSuggestions: action,
  getRandomizedSearchSuggestions: action,
  addPinnedSearchSuggestion: action,
  addRandomizedSearchSuggestion: action,
  deleteSearchSuggestion: action,
  pinnedSuggestions: observable,
  randomizedSuggestions: observable,
  snackbarIsOpen: observable,
  snackbarMessage: observable,
  snackbarVariant: observable
});

export default SearchSuggestionsStore;
