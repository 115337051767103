import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Select from "@material-ui/core/Select";
import { inject, observer } from "mobx-react";

class FieldCategoryPicker extends React.Component {
  render() {
    const { synonym } = this.props;
    const { pitsMetadataStore: store } = this.props.rootStore;
    const fieldId = synonym.fieldMappingId;
    const fromDb = synonym.fromDb;
    const fieldInfo = store.getFieldInfo(fieldId);
    const categoryToUse = synonym.category
      ? synonym.category
      : fieldInfo.category;

    return (
      <Select
        value={categoryToUse}
        disabled={fromDb}
        onChange={e => synonym.setCategory(e.target.value)}
      >
        {store.categories
          ? store.categories.map(c => (
              <MenuItem key={c} value={c}>
                {c}
              </MenuItem>
            ))
          : []}
      </Select>
    );
  }
}

export default inject("rootStore")(observer(FieldCategoryPicker));
