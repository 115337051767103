import {
  RouterStore as MobxRouterStore,
  syncHistoryWithStore
} from "mobx-react-router";
import { computed, decorate } from "mobx";
import AdjustVideo from "../components/AdjustVideo";
import VideoDiagnostics from "../components/VideoDiagnostics";
import Issues from "../components/Issues";
import ClearCache from "../components/ClearCache";
import RuleReport from "../components/RuleReport";
import VideoAssetOverrides from "../components/asset_overrides/VideoAssetOverrides";
import CheckOffset from "../components/CheckOffset";
import GameAudit from "../components/GameAudit";
import PlayAudit from "../components/PlayAudit";
import Synonyms from "../components/SynonymsTable";
import Macros from "../components/macros/MacrosTable";
import Macro from "../components/macros/Macro";
import SearchSuggestions from "../components/SearchSuggestions";
import createHistory from "history/createBrowserHistory";

class RouterStore extends MobxRouterStore {
  constructor() {
    super();
    this.history = syncHistoryWithStore(createHistory(), this);
  }

  pages = [
    {
      path: "/",
      label: "Video Diagnostics",
      component: VideoDiagnostics,
      isExact: true,
      showInNav: true
    },
    {
      path: "/issues",
      label: "Issues",
      component: Issues,
      isExact: true,
      showInNav: true
    },
    {
      path: "/rule-report",
      label: "Rule Report",
      component: RuleReport,
      isExact: true,
      showInNav: true
    },
    {
      path: "/issues/:id/adjustVideo",
      component: AdjustVideo,
      isExact: true,
      showInNav: false
    },
    {
      path: "/video-asset-overrides",
      label: "Video Asset Overrides",
      component: VideoAssetOverrides,
      isExact: true,
      showInNav: true
    },
    {
      path: "/offset-tool",
      label: "Offset Tool",
      component: CheckOffset,
      isExact: true,
      showInNav: true
    },
    {
      path: "/game-audit",
      label: "Game Audit",
      component: GameAudit,
      isExact: false,
      showInNav: true
    },
    {
      path: "/play-audit",
      label: "Play Audit",
      component: PlayAudit,
      isExact: false,
      showInNav: true
    },
    {
      path: "/cache-control",
      label: "Cache Control",
      component: ClearCache,
      isExact: false,
      showInNav: true
    },
    {
      path: "/synonyms",
      label: "Synonyms",
      component: Synonyms,
      isExact: false,
      showInNav: true,
      navMenu: "Free Text Search"
    },
    {
      path: "/macros",
      label: "Macros",
      component: Macros,
      isExact: true,
      showInNav: true,
      navMenu: "Free Text Search"
    },
    {
      path: "/macros/:id",
      label: "Macro",
      component: Macro,
      showInNav: false
    },
    {
      path: "/search-suggestions",
      label: "Search Suggestions",
      component: SearchSuggestions,
      isExact: true,
      showInNav: true,
      navMenu: "Free Text Search"
    }
  ];

  get navPages() {
    return this.pages
      .filter(p => p.showInNav)
      .reduce((acc, page) => {
        if (!page.navMenu) {
          acc.push(page);
          return acc;
        }
        const existingMenuItem = acc.find(
          element => element.menuLabel === page.navMenu
        );
        if (existingMenuItem) {
          existingMenuItem.items.push(page);
          return acc;
        }
        const menuItem = {
          menuLabel: page.navMenu,
          items: [page]
        };
        acc.push(menuItem);
        return acc;
      }, []);
  }
}

decorate(RouterStore, {
  navPages: computed
});

export default RouterStore;
