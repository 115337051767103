import { Menu, MenuItem, TableCell, TableRow } from "@material-ui/core";
import ConfirmDialog from "../ConfirmDialog";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { MoreVert } from "@material-ui/icons";
import withAuth from "../../containers/withAuth";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import { SPORTY_ROLES } from "../../constants/authConstants";

const styles = theme => ({
  tableCell: {
    padding: "0px 4px"
  }
});

class MacroOptions extends React.Component {
  state = {
    open: false,
    anchorEl: null
  };

  handleClick(e) {
    this.setState({ anchorEl: e.currentTarget, open: !this.state.open });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleOptionClick(callback) {
    this.setState({ open: false });
    callback();
  }

  render() {
    const { previewMacro, editMacro, deleteMacro } = this.props;
    return (
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={this.state.open ? "long-menu" : undefined}
          aria-expanded={this.state.open ? "true" : undefined}
          aria-haspopup="true"
          onClick={e => {
            this.handleClick(e);
          }}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button"
          }}
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={() => {
            this.handleClose();
          }}
        >
          <MenuItem
            onClick={() => {
              this.handleOptionClick(previewMacro);
            }}
          >
            Preview
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.handleOptionClick(editMacro);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.handleOptionClick(deleteMacro);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

class MacroTableRow extends React.Component {
  state = {
    confirmDialogIsOpen: false
  };

  previewMacro() {
    const { macro } = this.props;
    const url = `https://www.mlb.com/video/search?q=${encodeURI(
      macro.query
    )}+Order+By+Timestamp+DESC`;
    window.open(url, "_blank");
  }

  editMacro() {
    const { macro } = this.props;
    const { macroStore, routerStore } = this.props.rootStore;
    macroStore.reset();
    routerStore.history.push(`/macros/${macro.id}`);
  }

  deleteMacro = async () => {
    const { macro } = this.props;
    const { macrosStore } = this.props.rootStore;
    await macrosStore.deleteMacro(macro.id);
  };

  toggleConfirmDialog = () => {
    this.setState({ confirmDialogIsOpen: !this.state.confirmDialogIsOpen });
  };

  render() {
    const { macro } = this.props;
    return (
      <React.Fragment>
        <TableRow hover key={macro.text}>
          <TableCell align={"left"}>{macro.text}</TableCell>
          <TableCell align={"left"}>{macro.query}</TableCell>
          <TableCell align={"right"}>
            <MacroOptions
              previewMacro={() => {
                this.previewMacro();
              }}
              editMacro={() => {
                this.editMacro();
              }}
              deleteMacro={() => {
                this.toggleConfirmDialog();
              }}
            />
          </TableCell>
        </TableRow>
        <ConfirmDialog
          title="Delete Macro?"
          open={this.state.confirmDialogIsOpen}
          setOpen={this.toggleConfirmDialog}
          onConfirm={this.deleteMacro}
        >
          Are you sure you want to delete this macro?
          <br />
          {macro.text}
        </ConfirmDialog>
      </React.Fragment>
    );
  }
}

export default withAuth(
  inject("rootStore")(withStyles(styles)(observer(MacroTableRow))),
  SPORTY_ROLES
);
