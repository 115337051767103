import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import CreateIcon from "@material-ui/icons/Create";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ConfirmDialog from "../ConfirmDialog";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  button: {
    margin: theme.spacing.unit
  },
  input: {
    display: "none"
  }
});

class AssetOverrideRow extends Component {
  state = {
    confirmDialogIsOpen: false
  };

  toggleConfirmDialog = () => {
    this.setState({ confirmDialogIsOpen: !this.state.confirmDialogIsOpen });
  };

  handleDeleteOverride = async () => {
    const { id, rootStore } = this.props;
    const { videoAssetOverridesStore: store } = rootStore;
    store.handleDeleteOverride(id);
  };

  render() {
    const { id, rootStore } = this.props;
    const { assetOverrideDialogStore: store } = rootStore;

    return (
      <React.Fragment>
        <TableRow key={this.props.gamePk}>
          <TableCell component="th" scope="row">
            {this.props.gamePk}
          </TableCell>
          <TableCell>
            <TextField
              key={id}
              value={this.props.feedType}
              fullWidth
              disabled
            />
          </TableCell>
          <TableCell>
            <TextField
              key={id}
              value={this.props.override}
              fullWidth
              disabled
            />
          </TableCell>
          <TableCell>{this.props.gameDate}</TableCell>
          <TableCell>
            <TextField
              key={id}
              value={this.props.from || ""}
              fullWidth
              disabled
            />
          </TableCell>
          <TableCell>
            <TextField
              key={id}
              value={this.props.to || ""}
              fullWidth
              disabled
            />
          </TableCell>
          <TableCell>
            <TextField
              key={id}
              value={this.props.timecodeOffsetOverride || ""}
              disabled
            />
          </TableCell>
          <TableCell>
            <IconButton onClick={() => store.editOverride(this.props)}>
              <CreateIcon />
            </IconButton>
          </TableCell>
          <TableCell>
            <IconButton
              className={styles.button}
              aria-label="Delete"
              onClick={() => this.toggleConfirmDialog()}
            >
              <DeleteForeverIcon />
            </IconButton>
          </TableCell>
        </TableRow>
        <ConfirmDialog
          title="Delete Asset Override?"
          open={this.state.confirmDialogIsOpen}
          setOpen={this.toggleConfirmDialog}
          onConfirm={this.handleDeleteOverride}
        >
          Are you sure you want to delete this asset override:
          <br />
          {this.props.override}?
        </ConfirmDialog>
      </React.Fragment>
    );
  }
}

export default inject("rootStore")(
  withStyles(styles)(observer(AssetOverrideRow))
);
