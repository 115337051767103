import { inject, observer } from "mobx-react";
import React from "react";
import withAuth from "../../containers/withAuth";
import { withStyles } from "@material-ui/core/styles";
import { SPORTY_ROLES } from "../../constants/authConstants";
import styled from "styled-components";
import TablePagination from "@material-ui/core/TablePagination";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import MacroTableRow from "./MacroTableRow";
import { Refresh } from "@material-ui/icons";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
});

const Container = styled.div`
  margin-top: 20px;
  padding: 0 50px;
`;

const refreshFabStyle = {
  position: "absolute",
  top: 100,
  right: 32
};

const addFabStyle = {
  position: "absolute",
  bottom: 32,
  right: 32
};

class MacrosTable extends React.Component {
  componentDidMount() {
    const { macrosStore } = this.props.rootStore;
    macrosStore.loadMacros();
  }

  _handleChangePage = (event, page) => {
    const { macrosStore } = this.props.rootStore;
    macrosStore.setPage(page);
  };

  _handleChangeRowsPerPage = event => {
    const { macrosStore } = this.props.rootStore;
    macrosStore.setRowsPerPage(event.target.value);
  };

  render() {
    const { macroStore, macrosStore, routerStore } = this.props.rootStore;

    return (
      <Container>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} sm={10} md={8} lg={8}>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell key={"macro-text"} align={"left"}>
                      Macro
                    </TableCell>
                    <TableCell key={"macro-query"} align={"left"}>
                      Query
                    </TableCell>
                    <TableCell key={"macro-actions"} align={"right"}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {macrosStore.macros.map(macro => {
                    return <MacroTableRow key={macro.id} macro={macro} />;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={macrosStore.totalRows}
              rowsPerPage={macrosStore.rowsPerPage}
              page={macrosStore.currentPage}
              backIconButtonProps={{ "aria-label": "Previous Page" }}
              nextIconButtonProps={{ "aria-label": "Next Page" }}
              onRowsPerPageChange={this._handleChangeRowsPerPage}
              onPageChange={this._handleChangePage}
            />
          </Grid>
        </Grid>
        <Fab
          disabled={macrosStore.refreshing}
          aria-label="refresh"
          style={refreshFabStyle}
          onClick={() => {
            macrosStore.refreshMacros();
          }}
        >
          {macrosStore.refreshing ? (
            <CircularProgress color={"inherit"} size={"1.5rem"} />
          ) : (
            <Refresh />
          )}
        </Fab>
        <Fab
          color="primary"
          aria-label="add"
          style={addFabStyle}
          onClick={() => {
            macroStore.reset();
            routerStore.history.push("/macros/new");
          }}
        >
          <AddIcon />
        </Fab>
        <Backdrop
          style={{ color: "#fff", zIndex: 999 }}
          open={macrosStore.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

export default withAuth(
  inject("rootStore")(withStyles(styles)(observer(MacrosTable))),
  SPORTY_ROLES
);
