import { inject, observer } from "mobx-react";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import Slide from "@material-ui/core/Slide";
import ClipDetailsTable from "./ClipDetailsTable";
import RuleQueryDetailsTable from "./RuleQueriesDetailsTable";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ReportProblem from "@material-ui/icons/ReportProblem";
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";
import { getRuleUrl } from "../httpClients/sportyWebApi";
import { playIdsFromClips, playInfoFromExpectedClips } from "../utils/utils";
import PlayDetailsTable from "./PlayDetailsTable";

const styles = theme => ({
  iconGreen: {
    color: "green"
  },
  iconBlue: {
    color: "blue"
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class RuleRow extends React.Component {
  state = {
    clipsOpen: false,
    queriesOpen: false,
    playsOpen: false
  };

  _handleClipsOpen = clipIds => {
    const { ruleStore: store } = this.props.rootStore;
    store.selectedClips = clipIds;
    this.setState({ clipsOpen: true });
  };

  _handleClipsClose = () => {
    const { ruleStore: store } = this.props.rootStore;
    store.selectedClips = [];
    this.setState({ clipsOpen: false });
  };

  _handlePlaysOpen = playIds => {
    const { ruleStore: store } = this.props.rootStore;
    store.selectedMissingPlays = playIds;
    this.setState({ playsOpen: true });
  };

  _handlePlaysClose = () => {
    const { ruleStore: store } = this.props.rootStore;
    store.selectedMissingPlays = [];
    this.setState({ playsOpen: false });
  };

  _handleQueriesOpen = queries => {
    const { ruleStore: store } = this.props.rootStore;
    store.selectedQueries = queries;
    this.setState({ queriesOpen: true });
  };

  _handleQueriesClose = () => {
    const { ruleStore: store } = this.props.rootStore;
    store.selectedQueries = [];
    this.setState({ queriesOpen: false });
  };

  render() {
    const { rule, classes } = this.props;
    const { ruleStore: store } = this.props.rootStore;

    const ruleClips = store.clipsForRuleMap[rule.ruleId] || [];
    const expectedClips = store.expectedClipsForRuleMap[rule.ruleId] || [];

    const loading = !(
      ruleClips &&
      expectedClips &&
      ruleClips.clips &&
      expectedClips.expected >= 0
    );

    const status =
      ruleClips &&
      expectedClips &&
      ruleClips.clips &&
      ruleClips.clips.length === expectedClips.expected;

    let missingPlays = [];
    if (!status && ruleClips && expectedClips) {
      const clipPlayIds = playIdsFromClips(ruleClips.clips);
      const expectedPlaysInfo = playInfoFromExpectedClips(expectedClips);
      const missingPlayIds = _.difference(
        _.map(expectedPlaysInfo, p => p.playId),
        clipPlayIds
      );
      missingPlays = _.filter(expectedPlaysInfo, e =>
        missingPlayIds.includes(e.playId)
      );
    }

    return (
      <TableRow key={rule.ruleId}>
        <TableCell>
          {loading ? (
            <HourglassEmpty className={classes.iconBlue} color="secondary" />
          ) : status ? (
            <CheckCircle className={classes.iconGreen} />
          ) : (
            <ReportProblem className={classes.column} color="error" />
          )}
        </TableCell>
        <TableCell>
          <Tooltip title="Rule Details">
            <Button
              className={classes.button}
              href={getRuleUrl(rule.ruleId)}
              target="_blank"
            >
              {rule.ruleId}
            </Button>
          </Tooltip>
        </TableCell>
        <TableCell>{rule.name}</TableCell>
        <TableCell>{rule.frequency}</TableCell>
        <TableCell>
          <Tooltip title="Clip Details">
            <Button
              className={classes.button}
              onClick={() => this._handleClipsOpen(ruleClips.clips)}
            >
              {ruleClips.clips && ruleClips.clips.length >= 0 ? (
                ruleClips.clips.length
              ) : (
                <React.Fragment />
              )}
            </Button>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title="Queries details">
            <Button
              className={classes.button}
              onClick={() => this._handleQueriesOpen(expectedClips.queries)}
            >
              {expectedClips.expected >= 0 ? expectedClips.expected : ""}
            </Button>
          </Tooltip>
        </TableCell>
        <TableCell>
          {rule.frequency === "PLAY_END" ? (
            <Tooltip title="Missing Plays">
              <Button
                className={classes.button}
                onClick={() => this._handlePlaysOpen(missingPlays)}
              >
                {missingPlays ? missingPlays.length : ""}
              </Button>
            </Tooltip>
          ) : (
            "NA"
          )}
        </TableCell>

        <Dialog
          open={this.state.clipsOpen}
          onClose={this._handleClipsClose}
          TransitionComponent={Transition}
          maxWidth={false}
        >
          <DialogTitle id="clip-details">
            Clips for rule {rule.name} - {store.date}
          </DialogTitle>
          <ClipDetailsTable ruleType={rule.frequency} clips={ruleClips.clips} />
        </Dialog>

        <Dialog
          open={this.state.queriesOpen}
          onClose={this._handleQueriesClose}
          TransitionComponent={Transition}
          maxWidth={false}
        >
          <DialogTitle id="clip-details">
            Queries for rule {rule.name} - {store.date}
          </DialogTitle>
          <RuleQueryDetailsTable ruleQueriesDetail={expectedClips} />
        </Dialog>

        <Dialog
          open={this.state.playsOpen}
          onClose={this._handlePlaysClose}
          TransitionComponent={Transition}
          maxWidth={false}
        >
          <DialogTitle id="play-details">Play Details</DialogTitle>
          <PlayDetailsTable plays={store.selectedMissingPlays} />
        </Dialog>
      </TableRow>
    );
  }
}

export default inject("rootStore")(withStyles(styles)(observer(RuleRow)));
