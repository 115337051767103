import moment from "moment";
import { action, decorate, observable } from "mobx";
import _ from "lodash";

const DATE_FORMAT = "YYYY-MM-DD";
export const checkMissingWarnAlert = gameDetails => {
  if (!gameDetails.feeds || gameDetails.feeds.length === 0) {
    return "missing-feeds";
  }
  let feed_status = "good";
  gameDetails.feeds.forEach(f => {
    if (f.missing.length > 5) {
      feed_status = "alert";
    } else if (f.missing.length > 0) {
      feed_status = "warn"; //Need to check other feeds for alert
    }
  });
  return feed_status;
};

class VideoDiagnosticStore {
  date: "";

  constructor(jaredApi, statsApi) {
    this.jaredApi = jaredApi;
    this.statsApi = statsApi;
    // Hold date rollover until 10:00 AM
    this.date = moment()
      .subtract(10, "hours")
      .format(DATE_FORMAT);
    this.games = [];
    this.gameCache = [];
    this.gamesLoading = true;
    this.resendingDate = false;
    this.gamesResending = [];
    this.selectedFeed = null;
    this.selectedPlays = [];
  }

  fetchGamesForDate(date) {
    this.games = [];
    this.gamesLoading = true;
    this.jaredApi.getGamesForDate(date).then(r => {
      this.games = r;
      this.gamesLoading = false;
    });
  }

  fetchGameValidationDetails(gamePk) {
    this.jaredApi.getGameValidationDetails(gamePk).then(r => {
      this.gameCache.push(r);
    });
  }

  fetchFeedDetails(gamePk, feedType) {
    this.jaredApi.getFeedDetails(gamePk, feedType).then(r => {
      this.selectedFeed = { gamePk: gamePk, feedType: feedType, feeds: r };
    });
  }

  setDate(date) {
    this.date = date;
  }

  getGameVideoStatus(statusCode, gamePk) {
    let status = "unknown";

    if (!this.statsApi.hasGameStarted(statusCode)) {
      return "pre-game";
    }

    const feedDetails = _.find(this.gameCache, g => g.gamePk === gamePk);
    if (feedDetails) {
      return checkMissingWarnAlert(feedDetails);
    }

    return status;
  }

  getGameState(statusCode) {
    return this.statsApi.getGameState(statusCode);
  }

  resendForDate = () => {
    this.resendingDate = true;
    this.jaredApi.resendForDate(this.date).then(r => {
      this.resendingDate = false;
    });
  };

  resendGame = (gamePk, feed) => {
    const key = this.getGameKey(gamePk, feed);
    this.gamesResending.push(key);
    this.jaredApi.resendGame(gamePk, feed).then(r => {
      _.remove(this.gamesResending, g => g === key);
    });
  };

  getGameKey(gamePk, feed) {
    return gamePk + "_" + feed;
  }

  resendingGame = (gamePk, feed) => {
    const key = this.getGameKey(gamePk, feed);
    return this.gamesResending.includes(key);
  };
}

decorate(VideoDiagnosticStore, {
  date: observable,
  games: observable,
  gamesLoading: observable,
  gamesResending: observable,
  gameCache: observable,
  setDate: action,
  fetchGamesForDate: action,
  fetchFeedDetails: action,
  resendingDate: observable,
  resendForDate: action,
  resendingGame: observable,
  resendGame: action,
  selectedFeed: observable,
  selectedPlays: observable
});

export default VideoDiagnosticStore;
