import { inject, observer } from "mobx-react";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import ClipDetailsRow from "./ClipDetailsRow";
import { playIdsFromClips } from "../utils/utils";

const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    alignContent: "space-between"
  },
  containerItem: {
    margin: "auto",
    padding: `0 ${theme.spacing.unit}px`
  }
});

const columns = [
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Title"
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description"
  },
  {
    id: "num-plays",
    numeric: false,
    disablePadding: false,
    label: "Num Plays"
  },
  {
    id: "created-at",
    numeric: false,
    disablePadding: false,
    label: "Created At"
  },
  { id: "link", numeric: false, disablePadding: false, label: "" }
];

class ClipDetailsTable extends React.Component {
  componentWillUnmount() {
    const { clips } = this.props;
    this.playIds = playIdsFromClips(clips);
  }

  componentDidMount() {
    const { clips } = this.props;
    this.playIds = playIdsFromClips(clips);
  }

  render() {
    const { clips, ruleType } = this.props;

    return (
      <div>
        <Table>
          {clips && clips.length > 0 ? (
            <TableHead>
              <TableRow>
                {columns.map(column => {
                  return (column.ruleType && column.ruleType === ruleType) ||
                    !column.ruleType ? (
                    <TableCell
                      key={column.id}
                      numeric={column.numeric}
                      padding={column.disablePadding ? "none" : "default"}
                    >
                      {column.label}
                    </TableCell>
                  ) : null;
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {clips ? clips.map(c => (
                  <ClipDetailsRow key={c.clipId} clip={c} />
                ))
              : null}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default inject("rootStore")(
  withStyles(styles)(observer(ClipDetailsTable))
);
