import _ from "lodash";
import moment from "moment";

export const playIdsFromClips = clips => {
  return _.chain(clips)
    .filter(c => c.plays && c.plays.length === 1)
    .flatMap(c => c.plays)
    .map(c => c.playId)
    .value();
};

export const playInfoFromExpectedClips = (expected = {}) => {
  return expected.ruleType === "PLAY_END"
    ? _.chain(expected.queries)
        .map(p => p.playsInfo)
        .flatMap()
        .value()
    : [];
};

export const mapPlaysInfo = plays => {
  return _.map(plays, p => {
    return {
      playId: p.id,
      gamePk: p.gamePk,
      description: p.description
    };
  });
};

export const entryContainsWhitespace = entry => /\s/g.test(entry);

export const validateAssetNameInput = entry => entryContainsWhitespace(entry) ? "Asset Name cannot contain whitespace" : "";

export const isValidTimestamp = entry => !entry || new moment(entry).isValid();

export const validateTimestampInput = entry => !isValidTimestamp(entry) ? "Must be a valid timestamp" : "";

export const validateTimestamps = (from, to) => {
  if (to && !from) {
    return "Can't set To without From";
  }

  const fromDate = from ? new moment(from.trim()) : "";
  const toDate = to ? new moment(to.trim()) : "";
  if (fromDate && !fromDate.isValid()) {
    return "Invalid from timestamp";
  }

  if (toDate && !toDate.isValid()) {
    return "Invalid to timestamp";
  }

  if (toDate && fromDate.isAfter(toDate)) {
    return "From can't be greater than To";
  }
  return "";
};
