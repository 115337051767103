import axios from "axios";

const BASE_URL = process.env.REACT_APP_BIVO_BASE_URL;

class BivoApi {
  getJobs(gamePk) {
    return axios
      .get(`${BASE_URL}/jobs/game/${gamePk}`)
      .then(r => r.data);
  }
}

BivoApi.BASE_URL = BASE_URL;

export default BivoApi;
