import { action, decorate, observable } from "mobx";

class SynonymMapping {
  fieldMappingId = -1;
  keyword = "";
  synonyms = [];
  fromDb = true;
  inError = false;
  category = "";
  name = "";

  constructor(fieldMappingId, keyword, synonyms, fromDb) {
    this.fieldMappingId = fieldMappingId;
    this.keyword = keyword;
    this.synonyms = synonyms;
    this.fromDb = fromDb;
  }

  setCategory(category) {
    this.category = category;
    this.fieldMappingId = -1;
    this.name = "";
  }

  setName(name) {
    this.name = name;
  }
  setFieldMappingId(fieldMappingId) {
    this.fieldMappingId = fieldMappingId;
  }

  setKeyword(keyword) {
    this.keyword = keyword;
  }
}

decorate(SynonymMapping, {
  fieldMappingId: observable,
  category: observable,
  name: observable,
  keyword: observable,
  synonyms: observable,
  fromDb: observable,
  inError: observable,
  setName: action,
  setCategory: action
});
export default SynonymMapping;
