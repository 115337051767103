import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  withStyles
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import Select from "@material-ui/core/Select";
import {
    entryContainsWhitespace,
    isValidTimestamp,
    validateAssetNameInput,
    validateTimestampInput
} from "../../utils/utils";

const styles = theme => ({
  createNewOverrideButton: {
    float: "right",
    marginRight: 20,
    marginBottom: 20
  }
});

class AssetOverrideDialog extends React.Component {
  render() {
    const { assetOverrideDialogStore: store } = this.props.rootStore;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Fab
          size="small"
          color="secondary"
          aria-label="add"
          className={classes.createNewOverrideButton}
          onClick={() => (store.isOpen = true)}
        >
          <AddIcon />
        </Fab>
        <Dialog
          open={store.isOpen}
          onClose={() => store.resetValues()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="add-new-override-form">
            {store.id ? "Update override" : "Add new override"}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="gamePk"
              label="GamePK"
              type="text"
              inputProps={{ maxLength: 6 }}
              onChange={e => store.setGamePk(e.target.value)}
              value={store.gamePkValue}
            />
            <FormControl variant="standard" style={{ margin: 1 }} fullWidth>
              <InputLabel id="asset-override-feed-label">Feed</InputLabel>
              <Select
                labelId="asset-override-feed-label"
                id="asset-override-feed"
                value={store.feedType}
                onChange={e => (store.feedType = e.target.value)}
                label="Feed"
              >
                <MenuItem value="" />
                <MenuItem value={"CF"}>CF</MenuItem>
                <MenuItem value={"HOME"}>HOME</MenuItem>
                <MenuItem value={"AWAY"}>AWAY</MenuItem>
                <MenuItem value={"NETWORK"}>NETWORK</MenuItem>
                <MenuItem value={"HIGHHOME"}>HIGHHOME</MenuItem>
                <MenuItem value={"CAM4"}>CAM4</MenuItem>
                <MenuItem value={"1B-SIDEVIEW"}>1B-SIDEVIEW</MenuItem>
                <MenuItem value={"1B-BATTER-SIDEVIEW"}>
                  1B-BATTER-SIDEVIEW
                </MenuItem>
                <MenuItem value={"1B-PITCHER-SIDEVIEW"}>
                  1B-PITCHER-SIDEVIEW
                </MenuItem>
                <MenuItem value={"3B-SIDEVIEW"}>3B-SIDEVIEW</MenuItem>
                <MenuItem value={"3B-BATTER-SIDEVIEW"}>
                  3B-BATTER-SIDEVIEW
                </MenuItem>
                <MenuItem value={"3B-PITCHER-SIDEVIEW"}>
                  3B-PITCHER-SIDEVIEW
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              id="assetName"
              label="Asset Name"
              type="text"
              fullWidth
              error={entryContainsWhitespace(store.assetNameValue)}
              helperText={validateAssetNameInput(store.assetNameValue)}
              value={store.assetNameValue}
              onChange={e => (store.assetNameValue = e.target.value)}
            />
            <TextField
              margin="dense"
              id="From"
              label="From (UTC)"
              type="text"
              fullWidth
              error={!isValidTimestamp(store.from)}
              helperText={validateTimestampInput(store.from)}
              value={store.from}
              onChange={e => (store.from = e.target.value)}
            />
            <TextField
              margin="dense"
              id="to"
              label="To (UTC)"
              type="text"
              fullWidth
              error={!isValidTimestamp(store.to)}
              helperText={validateTimestampInput(store.to)}
              value={store.to}
              onChange={e => (store.to = e.target.value)}
            />
            <TextField
              margin="dense"
              id="to"
              label="Timecode Offset (frames)"
              type="text"
              fullWidth
              value={store.timecodeOffsetOverride}
              onChange={e => (store.timecodeOffsetOverride = e.target.value)}
            />
            {store.errorMessage !== "" && (
              <DialogContentText color="error">
                {store.errorMessage}
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => store.resetValues()} color="primary">
              Cancel
            </Button>
            <Button
              disabled={!store.isValid || store.saving}
              onClick={() => store.submitOverride()}
              color="primary"
              variant={"contained"}
            >
              {store.saving ? <CircularProgress size={"1.5rem"} /> : "Save"}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default inject("rootStore")(
  withStyles(styles)(observer(AssetOverrideDialog))
);
