import axios from "axios";

const BASE_URL = process.env.REACT_APP_JARED_BASE_URL;

export default class JaredApi {
  adjustVideo = ({ gamePk, playId, feedType, start, end }) => {
    return axios
      .post(
        `${BASE_URL}/admin/${gamePk}/plays/${playId}/${feedType}/adjust?start=${start}&end=${end}`
      )
      .then(r => r.data);
  };

  cutCustomClip = (assetName, assetId, timecodeOffset, start, end) => {
    return axios
      .post(
        `${BASE_URL}/admin/cut-custom-clip?assetName=${assetName}&assetId=${assetId}&timecodeOffset=${timecodeOffset}&start=${start}&end=${end}`
      )
      .then(r => r.data);
  };

  getGamesForDate = date => {
    return axios.get(`${BASE_URL}/dates/${date}/games`).then(r => r.data);
  };

  getGameValidationDetails = gamePk => {
    return axios.get(`${BASE_URL}/games/${gamePk}/validate`).then(r => r.data);
  };

  getFeedDetails = (gamePk, feedType) => {
    return axios
      .get(`${BASE_URL}/games/${gamePk}/feeds/${feedType}`)
      .then(r => r.data);
  };

  getCloudflareZones = () => {
    return axios.get(`${BASE_URL}/admin/cache/zones`).then(r => r.data);
  };

  clearCacheByUrl = url => {
    return axios
      .post(`${BASE_URL}/admin/cache/clear-by-url`, {
        url: url
      })
      .then(r => r.data);
  };

  clearCacheByTag = (domain, tag) => {
    return axios
      .post(`${BASE_URL}/admin/cache/clear-by-tag`, {
        domain: domain,
        tag: tag
      })
      .then(r => r.data);
  };

  fetchPlayInfo = (gamePk, playId) => {
    return axios
      .get(`${BASE_URL}/games/${gamePk}/plays/${playId}/info`)
      .then(r => r.data);
  };

  resendForDate = date => {
    return axios
      .get(`${BASE_URL}/dates/${date}/validate?resend=true`)
      .then(r => r.data);
  };

  resendGame = (gamePk, feed) => {
    return axios
      .get(`${BASE_URL}/games/${gamePk}/${feed}/validate?resend=true`)
      .then(r => r.data);
  };

  getNotificationStatusForAllLiveAndFutureGames = (type = "rth") => {
    return axios
      .get(`${BASE_URL}/admin/notifications/status?notification_type=${type}`)
      .then(r => r.data);
  };
  getNotificationStatusForGame = (gamePk, type) => {
    return axios
      .get(
        `${BASE_URL}/admin/notifications/status/${gamePk}?notification_type=${type}`
      )
      .then(r => r.data);
  };

  resumeNotificationsForGame = (gamePk, type = "rth") => {
    return axios.post(
      `${BASE_URL}/admin/notifications/resume/${gamePk}?notification_type=${type}`
    );
  };

  suspendNotificationsForGame = (gamePk, type = "rth") => {
    return axios.post(
      `${BASE_URL}/admin/notifications/suspend/${gamePk}?notification_type=${type}`
    );
  };

  resumeAllFutureNotifications = (type = "rth") => {
    return axios.post(
      `${BASE_URL}/admin/notifications/resume?notification_type=${type}`
    );
  };

  suspendAllFutureNotifications = (type = "rth") => {
    return axios.post(
      `${BASE_URL}/admin/notifications/suspend?notification_type=${type}`
    );
  };

  getVideoAssetOverrides = (startDate, endDate) => {
    return axios.get(
      `${BASE_URL}/admin/video/asset/overrides?start_date=${startDate}&end_date=${endDate}`
    );
  };

  getAssets = (gamePk: number) => {
    return axios.get(`${BASE_URL}/feeds/${gamePk}`).then(d => d.data);
  };

  upsertVideoAssetOverrides = assetOverride => {
    let url = `${BASE_URL}/admin/video/asset/overrides`;
    return axios.post(url, assetOverride);
  };

  deleteVideoAssetOverride = overrideId => {
    let url = `${BASE_URL}/admin/video/asset/override/delete/${overrideId}`;
    return axios.delete(url);
  };

  reindexPlay = (gamePk, playId) => {
    return axios
      .post(`${BASE_URL}/admin/games/${gamePk}/plays/${playId}/reindex`)
      .then(r => r.data);
  };
}
