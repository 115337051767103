import { action, decorate, observable, reaction } from "mobx";

class IssuesStore {
  constructor(sportyApi, clippyApi) {
    this.sportyApi = sportyApi;
    this.clippyApi = clippyApi;
    reaction(() => this.page, page => this.getIssues());
    reaction(() => this.rowsPerPage, rowsPerPage => this.getIssues());
  }

  issues = [];
  order = "asc";
  orderBy = "last-modified";
  selected = [];
  page = 0;
  rowsPerPage = 10;
  totalIssues = 0;

  getIssues() {
    this.sportyApi.getIssues(this.rowsPerPage, this.page).then(d => {
      this.issues = d.data;
      this.totalIssues = d.total;
    });
  }

  setIssues(issues) {
    this.issues = issues;
  }

  putIssue(issue) {
    return this.sportyApi.putIssue(issue);
  }
    
  setPage(page) {
    this.page = page;
  }

  setRowsPerPage(rowsPerPage) {
    this.rowsPerPage = rowsPerPage;
  }

  isSelected(id) {
    return this.selected.indexOf(id) !== -1;
  }

  handleSelect(id) {
    const selectedIndex = this.selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.selected.slice(), id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.selected.slice(1));
    } else if (selectedIndex === this.selected.length - 1) {
      newSelected = newSelected.concat(this.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.selected.slice(0, selectedIndex),
        this.selected.slice(selectedIndex + 1)
      );
    }

    this.selected = newSelected;
  }

  handleSortRequest(event, property) {
    const orderBy = property;
    let order = "desc";

    if (this.orderBy === property && this.order === "desc") {
      order = "asc";
    }

    const data =
      order === "desc"
        ? this.issues.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.issues.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.order = order;
    this.orderBy = orderBy;
    this.issues = data;
  }

  handleSelectAll(event, checked) {
    if (checked) {
      this.selected = this.issues.map(i => i.id);
    } else {
      this.selected = [];
    }
  }
}

decorate(IssuesStore, {
  issues: observable,
  selected: observable,
  page: observable,
  rowsPerPage: observable,
  totalIssues: observable,
  getIssues: action,
  setIssues: action,
  setPage: action,
  setRowsPerPage: action,
  handleSelection: action,
  handleSortRequest: action
});

export default IssuesStore;
