import { action, decorate, observable, reaction } from "mobx";
import dayjs from "dayjs";
import { toast } from "react-toastify";

class VideoAssetOverridesStore {
  constructor(jaredApi) {
    this.jaredApi = jaredApi;
    this.overrides = [];
    this.isLoading = false;
    this.startDate = dayjs().format("YYYY-MM-DD");
    this.endDate = dayjs().format("YYYY-MM-DD");

    reaction(
      () => this.startDate,
      rowsPerPage => this.loadOverrides()
    );
    reaction(
      () => this.endDate,
      rowsPerPage => this.loadOverrides()
    );
  }

  loadOverrides() {
    this.isLoading = true;
    return this.jaredApi
      .getVideoAssetOverrides(this.startDate, this.endDate)
      .then(r => {
        if (r.data.success) {
          this.overrides = r.data.results;
        } else {
          toast.error(`Failed to load overrides: ${r.data.message}`, {
            position: "bottom-left"
          });
          this.overrides = [];
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  handleOverrideChange(assetOverride) {
    this.isLoading = true;
    this.jaredApi
      .upsertVideoAssetOverrides(assetOverride)
      .then(r => {
        if (r.data.success) {
          toast.success(`Updated override for asset: ${assetOverride.id}`, {
            position: "bottom-left"
          });
          this.loadOverrides();
        } else {
          toast.error(`Failed to update override: ${r.data.message}`, {
            position: "bottom-left"
          });
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  handleDeleteOverride(assetId) {
    this.isLoading = true;
    this.jaredApi
      .deleteVideoAssetOverride(assetId)
      .then(r => {
        if (r.data.success) {
          toast.success(`Deleted override for asset: ${assetId}`, {
            position: "bottom-left"
          });
          this.loadOverrides();
        } else {
          toast.error(`Failed to delete asset: ${r.data.message}`, {
            position: "bottom-left"
          });
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}

decorate(VideoAssetOverridesStore, {
  isLoading: observable,
  loadOverrides: action,
  handleOverrideChange: action,
  overrides: observable,
  feedType: observable,
  startDate: observable,
  endDate: observable
});

export default VideoAssetOverridesStore;
