import { action, decorate, observable } from "mobx";
import debounce from "lodash/debounce";
import _ from "lodash";
class TypeAheadStore {
  suggestions = [];

  constructor(typeAheadApi) {
    this.typeAheadApi = typeAheadApi;
    this.fetchSuggestions = debounce(this.fetchSuggestions.bind(this), 200);
  }
  clearSuggestions() {
    this.suggestions = [];
  }
  fetchSuggestions(searchTerm, fieldId) {
    if (searchTerm && fieldId) {
      return this.typeAheadApi.getSuggestions(searchTerm, fieldId).then(d => {
        const sectionWithData = _.find(d, function(o) {
          return o.length > 0;
        });
        if (sectionWithData) {
          this.suggestions = sectionWithData.map(swd => swd.name);
        }
      });
    }
  }
}

decorate(TypeAheadStore, {
  suggestions: observable,
  fetchSuggestions: action,
  clearSuggestions: action,
  loading: observable
});

export default TypeAheadStore;
