import axios from "axios";

const BASE_URL = process.env.REACT_APP_ALOFT_BASE_URL;

class AloftApi {
  refreshFreeTextSearch() {
    return axios.post(`${BASE_URL}/data/freetext/refresh`).then(r => r.data);
  }
}

export default AloftApi;
