import { action, decorate, observable, reaction } from "mobx";
import SynonymMapping from "../model/SynonymMapping";
import _ from "lodash";

class SynonymsStore {
  rowsPerPage = 25;
  currentPage = 0;
  totalRows = 0;

  constructor(pitsApi) {
    this.pitsApi = pitsApi;
    this.synonyms = [];

    this.pitsApi.getSynonyms().then(d => {
      this.synonyms = this.buildSynMappings(d.data);
      this.totalRows = d.total;
    });

    this.totalRows = this.synonyms.length;
    reaction(() => this.currentPage, () => this.getSynonymsPage());
    reaction(() => this.rowsPerPage, () => this.getSynonymsPage());
  }

  buildSynMappings(data) {
    const syns = [];
    data.forEach(d => {
      syns.push(
        new SynonymMapping(d.fieldMappingId, d.value, d.synonyms, true)
      );
    });
    return syns;
  }

  getSynonymsPage() {
    this.pitsApi.getSynonyms(this.currentPage, this.rowsPerPage).then(d => {
      this.synonyms = this.buildSynMappings(d.data);
    });
  }

  getSynonyms(value) {
    return this.synonyms.filter(syn => syn.value === value);
  }

  setRowsPerPage(rowsPerPage) {
    this.rowsPerPage = rowsPerPage;
  }

  setPage(newPage) {
    this.currentPage = newPage;
  }

  addUnsavedRow(event) {
    this.synonyms.push(new SynonymMapping(-1, "", [], false));
  }
  handleSynonymChange(values, syn) {
    const currentSyns = syn.synonyms;
    const changedSyns = _.map(values, v => v.value);
    const deletedSyns = _.difference(currentSyns, changedSyns);
    deletedSyns.forEach(v =>
      this.pitsApi
        .deleteSynonym(syn.keyword, v)
        .then(() => (syn.fromDb = true))
        .catch(() => (syn.inError = true))
    );
    const addedSyns = _.difference(changedSyns, currentSyns);
    addedSyns.forEach(v =>
      this.pitsApi
        .addSynonym(syn.keyword, v)
        .then(() => (syn.fromDb = true))
        .catch(() => (syn.inError = true))
    );
    syn.synonyms = changedSyns;
  }
}

decorate(SynonymsStore, {
  synonyms: observable,
  fields: observable,
  currentPage: observable,
  totalRows: observable,
  setRowsPerPage: action,
  rowsPerPage: observable,
  setPage: action,
  unsavedSynonyms: observable
});

export default SynonymsStore;
