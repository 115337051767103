import { inject, observer } from "mobx-react";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { getPlayDoc } from "../httpClients/pitsApi";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";

const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    alignContent: "space-between"
  },
  containerItem: {
    margin: "auto",
    padding: `0 ${theme.spacing.unit}px`
  }
});

class PlayDetailsTable extends React.Component {
  render() {
    const { classes, plays } = this.props;
    return (
      <Table>
        <TableBody>
          {plays ? (
            plays.map(p => (
              <TableRow key={p.playId}>
                <TableCell>{p.playId}</TableCell>
                {p.description ? (
                  <TableCell>{p.description}</TableCell>
                ) : (
                  <React.Fragment />
                )}
                <TableCell>
                  <Button
                    className={classes.button}
                    href={`https://research.mlb.com/games/${p.gamePk}/plays/${
                      p.playId
                    }`}
                    target="_blank"
                  >
                    Research
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    className={classes.button}
                    href={`https://statsapi.mlb.com/api/v1/game/${p.gamePk}/${
                      p.playId
                    }/analytics/parsed`}
                    target="_blank"
                  >
                    BME
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    className={classes.button}
                    href={getPlayDoc(p.playId)}
                    target="_blank"
                  >
                    Search
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <React.Fragment />
          )}
        </TableBody>
      </Table>
    );
  }
}

export default inject("rootStore")(
  withStyles(styles)(observer(PlayDetailsTable))
);
