import { inject, observer } from "mobx-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import rem from "../utils/rem";
import React from "react";
import styled from "styled-components";
import GamesDetails from "./GamesDetails";
import Button from "@material-ui/core/Button";
import Loading from "./Loading";

import { withStyles } from "@material-ui/core/styles";
import "react-datepicker/dist/react-datepicker.css";
import withAuth from "../containers/withAuth";
import { ALL_VALID_ROLES } from "../constants/authConstants";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  input: {
    display: "none"
  }
});

const DatePickerWrapper = styled.div`
  display: inline-block;
  padding-right: ${rem(12)}
`;

const DateInput = styled.input`
  text-align: center;
  line-height: ${rem(16)};
  border-radius: ${rem(4)};
  border: 1px solid #aeaeae;
  font-size: ${rem(13)};
  padding: ${rem(6)} ${rem(10)} ${rem(6)};
  cursor: pointer;
`;

const Container = styled.div`
  background-color: ${props => props.theme.lightGray.rgb};
  grid-area: sidebar;
  padding: 15px 20px;
`;

class VideoDiagnostics extends React.Component {
  _handleDateChange(date) {
    const { videoDiagnosticStore: store } = this.props.rootStore;
    const formattedDate = date ? date.format("YYYY-MM-DD") : "";
    store.setDate(formattedDate);
    store.fetchGamesForDate(formattedDate);
  }

  _handleDateResend() {
    const { videoDiagnosticStore: store } = this.props.rootStore;
    store.resendForDate();
  }

  _handleRefresh() {
    const { videoDiagnosticStore: store } = this.props.rootStore;
    store.fetchGamesForDate(store.date);
  }

  componentDidMount() {
    const { videoDiagnosticStore: store } = this.props.rootStore;

    const date = store.date;
    store.fetchGamesForDate(date);
  }

  render() {
    const { videoDiagnosticStore: store } = this.props.rootStore;

    const { classes } = this.props;

    const selectedDate = new moment(store.date);

    return (
      <Container>
        <h1>Video Diagnostics</h1>
        <Container>
          <DatePickerWrapper>
            <DatePicker
              selected={selectedDate}
              onChange={date => this._handleDateChange(date)}
              dateFormat="LL"
              customInput={<DateInput />}
            />
          </DatePickerWrapper>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => this._handleDateResend()}
            disabled={store.resendingDate || store.gamesLoading}
          >
            {store.resendingDate ? "Sending..." : "Resend Missing Plays"}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => this._handleRefresh()}
            disabled={store.gamesLoading}
          >
            {store.gamesLoading ? "Loading..." : "Refresh"}
          </Button>
        </Container>
        <Container>
          {store.gamesLoading ? (
            <Loading />
          ) : (
            <GamesDetails games={store.games} />
          )}
        </Container>
      </Container>
    );
  }
}

export default withAuth(
  inject("rootStore")(withStyles(styles)(observer(VideoDiagnostics))),
  ALL_VALID_ROLES
);
