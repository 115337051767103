import { action, computed, decorate, observable } from "mobx";
import { toast } from "react-toastify";

class MacrosStore {
  rowsPerPage = 10;
  currentPage = 0;
  totalRows = 0;
  allMacros = [];
  loading = false;
  refreshing = false;

  constructor(pitsApi, aloftApi, pitsMetadataStore) {
    this.pitsApi = pitsApi;
    this.aloftApi = aloftApi;
    this.pitsMetadataStore = pitsMetadataStore;
  }

  async loadMacros() {
    this.loading = true;
    const res = await this.pitsApi.getMacros();
    this.allMacros = res.data.map(m => {
      return { id: m.id, text: m.phrase, query: m.queryMapping };
    });
    this.totalRows = this.allMacros.length;
    this.currentPage = 0;
    this.loading = false;
  }

  async deleteMacro(macroId) {
    const res = await this.pitsApi.deleteMacro(macroId);
    if (res.requestStatus.statusCode >= 400) {
      toast.error(`Failed to delete macro: ${res.requestStatus.msg}`, {
        position: "bottom-left"
      });
    } else {
      toast.success(`Deleted macro: ${macroId}`, { position: "bottom-left" });
      await this.loadMacros();
    }
  }

  async refreshMacros() {
    this.refreshing = true;
    this.aloftApi
      .refreshFreeTextSearch()
      .then(() => {
        toast.success(`Macros refreshed and ready for search`, {
          position: "bottom-left"
        });
        this.refreshing = false;
      })
      .catch(result => {
        toast.error(`Failed to refresh macros: ${result}`, {
          position: "bottom-left"
        });
        this.refreshing = false;
      });
  }

  setRowsPerPage(rowsPerPage) {
    this.rowsPerPage = rowsPerPage;
  }

  setPage(newPage) {
    this.currentPage = newPage;
  }

  get macros() {
    return this.allMacros.slice(
      this.currentPage * this.rowsPerPage,
      this.currentPage * this.rowsPerPage + this.rowsPerPage
    );
  }
}

decorate(MacrosStore, {
  allMacros: observable,
  loading: observable,
  refreshing: observable,
  macros: computed,
  currentPage: observable,
  totalRows: observable,
  setRowsPerPage: action,
  rowsPerPage: observable,
  setPage: action,
  deleteMacro: action
});

export default MacrosStore;
