import React from "react";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router-dom";

export default (WrappedComponent, requestedPermission = null) => {
  class WithAuth extends React.Component {
    render() {
      const { authStore } = this.props;
      if (authStore.isGettingUser) {
        return null;
      } else if (
        requestedPermission &&
        !authStore.checkPermission(requestedPermission)
      ) {
        return <Redirect to="/invalidPermissions" />;
      } else if (authStore.isAuthenticated) {
        return <WrappedComponent {...this.props} />;
      } else {
        return <Redirect to="/invalidPermissions" />;
      }
    }
  }

  return inject("authStore")(observer(WithAuth));
};
