export const issueStatusDisplayName = issueStatus => {
  switch (issueStatus) {
    case "NEEDS_ATTENTION":
      return "Needs Attention";
    case "PENDING":
      return "Pending";
    case "RESOLVED":
      return "Resolved";
    case "ARCHIVED":
      return "ARCHIVED";
    default:
  }
};

export const reasonDisplayName = reason => {
  switch (reason) {
    case "BAD_SEARCH_RESULT":
      return "Search Criteria";
    case "POOR_VIDEO_QUALITY":
      return "Video Quality";
    case "TOO_SHORT_OR_LONG":
      return "Video Length";
    default:
  }
};
