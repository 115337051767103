import React from "react";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import withAuth from "../containers/withAuth";
import { ALL_VALID_ROLES } from "../constants/authConstants";
import {
  Backdrop,
  CircularProgress, FormControl, InputLabel, MenuItem, Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import ReactJson from "react-json-view";
import { VideocamOff } from "@material-ui/icons";
import SearchBar from "material-ui-search-bar";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: 150
  }
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 50px;
`;

const VideoContainer = styled.div`
  position: relative;
`;

const Video = styled.video`
  padding: 0 10px;
  display: inline-block;
`;

const VideoFeedLabel = styled.span`
  position: absolute;
  top: 0;
  left: 10px;
  padding: 6px;
  z-index: 1;
  color: white;
  background: #a5a5a5c4;
`;

class PlayAudit extends React.Component {
  componentDidMount() {
    const { playAuditStore } = this.props.rootStore;
    const params = new URLSearchParams(this.props.location.search);
    if (params.get("playId")) {
      playAuditStore.setPlayId(params.get("playId"));
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { playAuditStore } = this.props.rootStore;
    const params = new URLSearchParams(this.props.location.search);
    if (params.get("playId") && params.get("playId") !== playAuditStore.playId) {
      playAuditStore.setPlayId(params.get("playId"));
    }
  }

  _handleChangePage = (event, page) => {
    const { playAuditStore } = this.props.rootStore;
    playAuditStore.currentPage = page;
  };

  _handleChangeRowsPerPage = event => {
    const { playAuditStore } = this.props.rootStore;
    playAuditStore.rowsPerPage = event.target.value;
  };

  render() {
    const { playAuditStore, routerStore } = this.props.rootStore;

    return (
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <TextField
              label="Play ID"
              variant="standard"
              fullWidth
              style={{ marginTop: "1rem" }}
              value={playAuditStore.playId}
              onChange={event => routerStore.history.push(`/play-audit?playId=${event.target.value}`)}
            />
            <TextField
              margin="dense"
              id="gamePk"
              label="GamePk"
              type="text"
              fullWidth
              disabled
              value={playAuditStore.gamePk}
            />
            <TextField
              margin="dense"
              id="startTime"
              label="StartTime"
              type="text"
              fullWidth
              disabled
              value={playAuditStore.startTime}
            />
            <TextField
              margin="dense"
              id="pitchTime"
              label="Pitch Time"
              type="text"
              fullWidth
              disabled
              value={playAuditStore.pitchTime}
            />
            <TextField
              margin="dense"
              id="endTime"
              label="End Time"
              type="text"
              fullWidth
              disabled
              value={playAuditStore.endTime}
            />
            <Button
              variant={"contained"}
              color={"primary"}
              disabled={
                !playAuditStore.actionsEnabled || playAuditStore.reindexing
              }
              style={{ marginTop: "1rem" }}
              onClick={() => playAuditStore.reindexPlay()}
            >
              Reindex Play
            </Button>
          </Grid>
          <Grid
            item
            xs={8}
            style={{ display: "flex", marginTop: "1rem", overflowX: "scroll" }}
          >
            {playAuditStore.videos.length > 0 ? (
              <React.Fragment>
                {playAuditStore.videos.map(video => {
                  return (
                    <VideoContainer>
                      <Video
                        src={video.link}
                        height="300px"
                        type="video/mp4"
                        controls
                      />
                      <VideoFeedLabel>{video.feedType}</VideoFeedLabel>
                    </VideoContainer>
                  );
                })}
              </React.Fragment>
            ) : (
              <div style={{ textAlign: "center", width: "100%" }}>
                {playAuditStore.playId && (
                  <VideocamOff
                    style={{
                      fontSize: "3rem",
                      marginTop: "100px",
                      width: "100%"
                    }}
                  />
                )}
              </div>
            )}
          </Grid>
          {playAuditStore.playId && (
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", minHeight: "40rem" }}
            >
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        key={"event-time"}
                        align={"left"}
                        style={{ width: "25%" }}
                      >
                        Event Time
                      </TableCell>
                      <TableCell
                        key={"event-type"}
                        align={"left"}
                        style={{ width: "15%" }}
                      >
                        <FormControl variant="standard" fullWidth>
                          <InputLabel id="event-type-filter-label">Event Type</InputLabel>
                          <Select
                            labelId="event-type-filter-label"
                            id="event-type-filter"
                            value={playAuditStore.eventTypeFilter}
                            onChange={(event) => playAuditStore.setEventTypeFilter(event.target.value)}
                            label="Event Type"
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={"PLAY_RECEIVED"}>Play Received</MenuItem>
                            <MenuItem value={"PLAY_HYDRATED"}>Play Hydrated</MenuItem>
                            <MenuItem value={"PLAY_INDEXED"}>Play Indexed</MenuItem>
                            <MenuItem value={"CONTENT_INDEXED"}>Content Indexed</MenuItem>
                            <MenuItem value={"CLIP_CREATED"}>Clip Created</MenuItem>
                            <MenuItem value={"RULE_PROCESSED"}>Rule Processed</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell
                        key={"event-span"}
                        align={"left"}
                        style={{ width: "25%" }}
                      >
                        <SearchBar
                          value={playAuditStore.spanIdFilter}
                          placeholder={"Span ID"}
                          onChange={value =>
                            playAuditStore.setSpanIdFilter(value)
                          }
                          onCancelSearch={() =>
                            playAuditStore.setSpanIdFilter("")
                          }
                        />
                      </TableCell>
                      <TableCell
                        key={"event-actions"}
                        align={"right"}
                        style={{ width: "40%" }}
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {playAuditStore.auditEvents.map(auditEvent => {
                      return (
                        <TableRow hover key={auditEvent.id}>
                          <TableCell align={"left"}>
                            {auditEvent.eventTimeFormatted}{" "}
                            {auditEvent.timeDiff && (
                              <span>({auditEvent.timeDiff})</span>
                            )}
                          </TableCell>
                          <TableCell align={"left"}>
                            {auditEvent.eventType}
                          </TableCell>
                          <TableCell align={"left"}>
                            {auditEvent.spanId}
                          </TableCell>
                          <TableCell align={"right"}>
                            {auditEvent.document !== undefined ? (
                              <Button
                                onClick={() =>
                                  window.open(auditEvent.document.url, "_blank")
                                }
                              >
                                DOC
                              </Button>
                            ) : (
                              auditEvent.eventData && (
                                <ReactJson
                                  style={{ float: "right", textAlign: "left" }}
                                  src={auditEvent.eventData}
                                  collapsed={true}
                                  sortKeys={true}
                                  enableClipboard={false}
                                />
                              )
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={playAuditStore.totalRows}
                rowsPerPage={playAuditStore.rowsPerPage}
                page={playAuditStore.currentPage}
                backIconButtonProps={{ "aria-label": "Previous Page" }}
                nextIconButtonProps={{ "aria-label": "Next Page" }}
                onRowsPerPageChange={this._handleChangeRowsPerPage}
                onPageChange={this._handleChangePage}
              />
            </Grid>
          )}
        </Grid>
        <Backdrop
          style={{ color: "#fff", zIndex: 999 }}
          open={playAuditStore.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

export default withAuth(
  inject("rootStore")(withStyles(styles)(observer(PlayAudit))),
  ALL_VALID_ROLES
);
