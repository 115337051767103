import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Snackbar from "./Snackbar";
import withAuth from "../containers/withAuth";
import { ALL_VALID_ROLES } from "../constants/authConstants";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: 150
  }
});

const Container = styled.div`
  display: flex;
  margin-top: 20px;
  padding: 0 50px;
`;

const ControlFields = styled.div`
  flex: 1 1 auto;
  margin-top: 20px;
  padding: 0 50px;
`;

class ClearCache extends React.Component {
  render() {
    const {
      rootStore: { clearCacheStore }
    } = this.props;

    const zones = clearCacheStore.zones ? clearCacheStore.zones : [];
    return clearCacheStore.loading ? (
      <Container />
    ) : (
      <ControlFields>
        <InputLabel id="cache-clear-type-label">Clear Type</InputLabel>
        <br />
        <Select
          id="cache-clear-type"
          labelid="cache-clear-type-label"
          value={clearCacheStore.selectedCacheClearType}
          onChange={e => {
            clearCacheStore.setSelectedCacheClearType(e.target.value);
          }}
        >
          {clearCacheStore.cacheClearTypes.map(t => (
            <MenuItem key={"mi-" + t} value={t}>
              {t}
            </MenuItem>
          ))}
        </Select>

        <br />
        <InputLabel id="cache-domain-label">Cache Domain</InputLabel>
        <br />
        {clearCacheStore.selectedCacheClearType === "TAG" ? (
          <Select
            id="cache-domain"
            labelid="cache-domain-label"
            value={clearCacheStore.selectedDomain}
            disabled={clearCacheStore.selectedDomain === "URL"}
            onChange={e => {
              clearCacheStore.setSelectedDomain(e.target.value);
            }}
          >
            {zones.map(z => (
              <MenuItem key={"zone-" + z.id} value={z.name}>
                {z.name}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <TextField disabled={true} />
        )}
        <br />

        <TextField
          id="cache-target"
          label="URL or Tag"
          multiline
          fullWidth
          rows={4}
          onChange={e => {
            clearCacheStore.setCacheTarget(e.target.value);
          }}
        />

        <br />
        <Button
          key="clear-cache-submit"
          size="small"
          color="primary"
          variant="raised"
          onClick={() => clearCacheStore.submit()}
          disabled={clearCacheStore.clearLoading}
        >
          Submit
        </Button>
        <br />
        <Snackbar
          actions={
            clearCacheStore.selectedCacheClearType === "URL" &&
            clearCacheStore.clearSuccess
              ? [
                  <Button
                    key="open"
                    color="primary"
                    size="small"
                    href={clearCacheStore.cacheTarget}
                    target="_blank"
                  >
                    Open
                  </Button>
                ]
              : []
          }
          isOpen={clearCacheStore.snackbarIsOpen}
          message={clearCacheStore.responseMessage}
          variant={clearCacheStore.responseVariant}
          onCloseHandler={(e, r) => clearCacheStore.closeSnackbar(e, r)}
        />
      </ControlFields>
    );
  }
}

export default withAuth(
  inject("rootStore")(withStyles(styles)(observer(ClearCache))),
  ALL_VALID_ROLES
);
