import React from "react";
import { inject, observer } from "mobx-react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

class TypeAheadDropDown extends React.Component {
  render() {
    const { onSelect, fieldMappingId } = this.props;
    const { typeAheadStore: tStore } = this.props.rootStore;
    return (
      <Autocomplete
        size={"small"}
        value={this.props.value}
        multiple={this.props.multiple}
        options={tStore.suggestions}
        onChange={(event, value) => {
          onSelect(value);
        }}
        renderInput={params => (
          <TextField
            {...params}
            margin="none"
            variant="standard"
            label={`Search`}
            onBlur={() => {
              tStore.clearSuggestions();
            }}
            onChange={e => {
              tStore.fetchSuggestions(e.target.value, fieldMappingId);
            }}
            InputProps={{
              ...params.InputProps,
              type: "search"
            }}
          />
        )}
      />
    );
  }
}

export default inject("rootStore")(observer(TypeAheadDropDown));
