import { inject, observer } from "mobx-react";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {
  issueStatusDisplayName,
  reasonDisplayName
} from "../utils/displayNames";
import Snackbar from "./Snackbar";

const Wrapper = styled.div`
  display: flex;
  margin-top: 20px;
  padding: 0 50px;
`;

const PlayDetails = styled.div`
  flex: 1 1 200px;
  padding: 0 25px;
`;

const Video = styled.video`
  display: block;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 768px;
  height: 432px;
`;

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: 150
  }
});

class AdjustVideo extends React.Component {
  componentDidMount() {
    const {
      match: { params },
      rootStore: { adjustIssueStore }
    } = this.props;
    adjustIssueStore.setIssue(params.id);
  }

  componentWillUnmount() {
    const {
      rootStore: { adjustIssueStore }
    } = this.props;
    adjustIssueStore.setIssue({});
  }

  render() {
    const {
      classes,
      rootStore: { adjustIssueStore }
    } = this.props;
    const issue = adjustIssueStore.issue;
    const playDetails = [
      {
        id: "created-at",
        label: "Issue Submitted On",
        value: moment(issue.createdAt).format("MMMM D, YYYY")
      },
      { id: "game-pk", label: "Game PK", value: issue.gamePk },
      { id: "play-id", label: "Play ID", value: issue.playId },
      { id: "feed", label: "Feed", value: issue.feedType },
      {
        id: "status",
        label: "Status",
        value: issueStatusDisplayName(issue.status)
      },
      {
        id: "reason",
        label: "Reason",
        value: reasonDisplayName(issue.issueType)
      },
      { id: "comment", label: "Comment", value: issue.comment }
    ];

    return (
      <Wrapper>
        <div>
          {adjustIssueStore.isLoading ? (
            <LoadingWrapper>
              <CircularProgress />
            </LoadingWrapper>
          ) : (
            <React.Fragment>
              <Video
                src={issue.videoSrc}
                width="768"
                height="432"
                type="video/mp4"
                controls
              />
              <TextField
                id="new-start-time"
                label="New Start Time"
                className={classes.textField}
                error={adjustIssueStore.newStartTimeHasError}
                margin="normal"
                placeholder={"+/- 1"}
                helperText={
                  adjustIssueStore.newStartTimeHasError
                    ? "Can't be blank"
                    : "Negative integer will extend clip"
                }
                onChange={e => (adjustIssueStore.newStartTime = e.target.value)}
                value={adjustIssueStore.newStartTime}
              />
              <TextField
                id="new-end-time"
                label="New End Time"
                className={classes.textField}
                error={adjustIssueStore.newEndTimeHasError}
                margin="normal"
                placeholder={"+/- 1"}
                helperText={
                  adjustIssueStore.newEndTimeHasError
                    ? "Can't be blank"
                    : "Negative integer will trim clip"
                }
                onChange={e => (adjustIssueStore.newEndTime = e.target.value)}
                value={adjustIssueStore.newEndTime}
              />
              <Button
                size="small"
                color="primary"
                variant="raised"
                onClick={() => adjustIssueStore.submit()}
              >
                Submit for Recutting
              </Button>
            </React.Fragment>
          )}
        </div>
        <PlayDetails>
          {playDetails.map(detail => (
            <Typography variant="body2">
              <strong>{detail.label}:</strong> {detail.value}
            </Typography>
          ))}
        </PlayDetails>
        <Snackbar
          isOpen={adjustIssueStore.snackbarIsOpen}
          message={adjustIssueStore.snackbarMessage}
          variant={adjustIssueStore.snackbarVariant}
          onCloseHandler={(e, r) => adjustIssueStore.closeSnackbar(e, r)}
          autoHideDuration={4000}
        />
      </Wrapper>
    );
  }
}

export default inject("rootStore")(withStyles(styles)(observer(AdjustVideo)));
