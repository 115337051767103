import React from "react";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GameDetails from "./GameDetails";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ReportProblem from "@material-ui/icons/ReportProblem";
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";
import VideocamOff from "@material-ui/icons/VideocamOff";
import { yellow } from "@material-ui/core/colors";
import _ from "lodash";

const styles = theme => ({
  root: {
    width: "100%"
  },
  column: {
    flexBasis: "33.33%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  iconGreen: {
    color: "green"
  },
  iconBlue: {
    color: "blue"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
});

class GamesDetails extends React.Component {
  render() {
    const { games, classes, rootStore } = this.props;

    const { videoDiagnosticStore: store } = rootStore;

    return (
      <div>
        {games.length === 0 ? "No games!" : <React.Fragment />}
        {games.map(g => (
          <ExpansionPanel key={g.gamePk}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div className={classes.column}>
                {store.getGameVideoStatus(g.status, g.gamePk) === "unknown" && (
                  <HourglassEmpty
                    className={classes.iconBlue}
                    color="secondary"
                  />
                )}

                {store.getGameVideoStatus(g.status, g.gamePk) === "alert" && (
                  <ReportProblem className={classes.column} color="error" />
                )}
                {store.getGameVideoStatus(g.status, g.gamePk) === "warn" && (
                  <ReportProblem
                    className={classes.column}
                    style={{ color: yellow[600] }}
                  />
                )}
                {store.getGameVideoStatus(g.status, g.gamePk) ===
                  "missing-feeds" && (
                  <VideocamOff className={classes.column} color="error" />
                )}
                {_.includes(
                  ["pre-game", "good"],
                  store.getGameVideoStatus(g.status, g.gamePk)
                ) && <CheckCircle className={classes.iconGreen} />}
              </div>
              <div className={classes.column}>
                <Typography className={classes.heading}>
                  {g.away} @ {g.home}
                </Typography>
              </div>
              <div className={classes.column}>
                <Typography className={classes.secondaryHeading}>
                  {g.gamePk}
                </Typography>
              </div>
              <div className={classes.column}>
                <Typography className={classes.heading}>
                  {store.getGameState(g.status)}
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GameDetails gamePk={g.gamePk} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </div>
    );
  }
}

export default inject("rootStore")(withStyles(styles)(observer(GamesDetails)));
