import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { inject, observer } from "mobx-react";
import DatePicker from "react-datepicker";
import queryString from "query-string";
import RuleDetailsTable from "./RuleDetailsTable";
import Loading from "./Loading";
import "react-datepicker/dist/react-datepicker.css";
import rem from "../utils/rem";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import withAuth from "../containers/withAuth";
import { ALL_VALID_ROLES } from "../constants/authConstants";

const Container = styled.div`
  margin-top: 20px;
  padding: 0 50px;
`;

const DateInput = styled.input`
  text-align: center;
  line-height: ${rem(16)};
  border-radius: ${rem(4)};
  border: 1px solid #aeaeae;
  font-size: ${rem(13)};
  padding: ${rem(6)} ${rem(10)} ${rem(6)};
  cursor: pointer;
`;

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300
  },
  formControlLabel: {
    labelPlacementStart: "end",
    disabled: "true"
  }
});

class RuleReport extends React.Component {
  _handleDateChange(date) {
    const { ruleStore: store } = this.props.rootStore;
    const formattedDate = date ? date.format("YYYY-MM-DD") : "";
    store.setDate(formattedDate);
    store.setPage(0);
    store.getRules();
  }

  _handleEnabledChange(enabled) {
    const { ruleStore: store } = this.props.rootStore;
    store.setEnabled(enabled);
    store.setPage(0);
    store.getRules();
  }

  _handleFrequencyChange(frequency) {
    const { ruleStore: store } = this.props.rootStore;
    store.setFrequency(frequency);
    store.setPage(0);
    store.getRules();
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    const { ruleStore: store } = this.props.rootStore;

    if (params.date) {
      store.setDate(params.date);
    }

    if (params.frequency) {
      store.setFrequency(params.frequency);
    }

    store.getRules();
  }

  render() {
    const { ruleStore: store } = this.props.rootStore;
    const {
      enabled: selectedEnabled,
      frequency: selectedFrequency,
      rules,
      rulesLoading
    } = store;
    const { classes } = this.props;
    const selectedDate = new moment(store.date);
    return (
      <Container>
        <h1>Rule Report</h1>
        <FormGroup row className={classes.root} autoComplete="off">
          <FormControl className={classes.formControl}>
            <DatePicker
              id="run-date"
              selected={selectedDate}
              onChange={date => this._handleDateChange(date)}
              dateFormat="LL"
              customInput={<DateInput />}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="rule-frequency">Rule Frequency</InputLabel>
            <Select
              id="rule-frequency"
              value={selectedFrequency}
              onChange={event =>
                this._handleFrequencyChange(event.target.value)
              }
            >
              <MenuItem value={"ALL"}>All</MenuItem>
              <MenuItem value={"ALL_GAMES_END"}>All Games End</MenuItem>
              <MenuItem value={"PLAY_END"}>Play End</MenuItem>
              <MenuItem value={"GAME_END"}>Game End</MenuItem>
              <MenuItem value={"INNING_END"}>Inning End</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="rule-enabled">Rule Enabled</InputLabel>
            <Select
              id="rule-enabled"
              value={selectedEnabled.toString()}
              onChange={event => this._handleEnabledChange(event.target.value)}
            >
              <MenuItem value={"true"}>True</MenuItem>
              <MenuItem value={"false"}>False</MenuItem>
            </Select>
          </FormControl>
        </FormGroup>
        <Container>
          {rulesLoading ? <Loading /> : <RuleDetailsTable rules={rules} />}
        </Container>
      </Container>
    );
  }
}

export default withAuth(
  inject("rootStore")(withStyles(styles)(observer(RuleReport))),
  ALL_VALID_ROLES
);
