import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import IssuesTable from "./IssuesTable";
import VideoPlayer from "./VideoPlayer";
import withAuth from "../containers/withAuth";
import { ALL_VALID_ROLES } from "../constants/authConstants";

const Container = styled.div`
  margin-top: 20px;
  padding: 0 50px;
`;

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  }
});

class Issues extends React.Component {
  render() {
    return (
      <Container>
        <IssuesTable />
        <VideoPlayer />
      </Container>
    );
  }
}

export default withAuth(withStyles(styles)(Issues), ALL_VALID_ROLES);
