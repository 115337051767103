import { action, decorate, observable } from "mobx";

class AdjustIssueStore {
  constructor(sportyApi, clippyApi, jaredApi, issuesStore) {
    this.sportyApi = sportyApi;
    this.clippyApi = clippyApi;
    this.jaredApi = jaredApi;
    this.issuesStore = issuesStore;
  }

  issue = {
    videoSrc: "",
    playId: "",
    gamePk: 0,
    status: "",
    reason: "",
    feedType: "",
    issueType: "",
    createdAt: "",
    comment: ""
  };
  newStartTime = null;
  newEndTime = null;
  newStartTimeHasError = false;
  newEndTimeHasError = false;
  isLoading = false;
  snackbarIsOpen = false;
  snackbarMessage = "";
  snackbarVariant = "";

  getIssue(id) {
    const issue = this.issuesStore.issues.find(i => i.id === id);
    return issue
      ? Promise.resolve(issue)
      : this.sportyApi.getIssue(id).then(d => d.data[0]);
  }

  randomNum() {
    return Math.round(new Date().getTime() / 1000);
  }

  setIssue(id) {
    this.getIssue(id).then(i => {
      this.clippyApi.getVideoUrl(i.gamePk, i.playId, i.feedType).then(d => {
        this.issue = i;
        this.issue.videoSrc = `${
          d.data[0].link
        }?cachebuster=${this.randomNum()}`;
      });
    });
  }

  isValid() {
    let isValid = true;
    if (this.newStartTime === null) {
      this.newStartTimeHasError = true;
      isValid = false;
    } else {
      this.newStartTimeHasError = false;
    }
    if (this.newEndTime === null) {
      this.newEndTimeHasError = true;
      isValid = false;
    } else {
      this.newEndTimeHasError = false;
    }
    return isValid;
  }

  resetForm() {
    this.newStartTime = null;
    this.newEndTime = null;
  }

  submit() {
    if (!this.isValid()) {
      return;
    }
    this.isLoading = true;
    this.jaredApi
      .adjustVideo({
        gamePk: this.issue.gamePk,
        playId: this.issue.playId,
        feedType: this.issue.feedType.toLowerCase(),
        start: this.newStartTime,
        end: this.newEndTime
      })
      .then(d => {
        this.issue.videoSrc = `${
          this.issue.videoSrc.split("?")[0]
        }?cachebuster=${this.randomNum()}`;
        this.resetForm();
        this.showSnackbar("success", "Video successfully recut!");
      })
      .catch(e =>
        this.showSnackbar("error", "Oops looks like something went wrong")
      )
      .finally(() => {
        this.isLoading = false;
      });
  }

  showSnackbar(variant, message) {
    this.snackbarVariant = variant;
    this.snackbarMessage = message;
    this.snackbarIsOpen = true;
  }

  closeSnackbar(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.snackbarVariant = "";
    this.snackbarMessage = "";
    this.snackbarIsOpen = false;
  }
}

decorate(AdjustIssueStore, {
  isLoading: observable,
  issue: observable,
  newStartTime: observable,
  newEndTime: observable,
  newStartTimeHasError: observable,
  newEndTimeHasError: observable,
  snackbarIsOpen: observable,
  snackbarMessage: observable,
  snackbarVariant: observable,
  setIssue: action,
  resetForm: action
});

export default AdjustIssueStore;
