import { action, decorate, observable, reaction, computed } from "mobx";
import moment from "moment";
import _ from "lodash";
import { mapPlaysInfo } from "../utils/utils";

class RuleStore {
  rowsPerPage: 10;
  page: 0;
  totalRules: 0;

  constructor(sportyApi, pitsApi, jaredApi) {
    this.sportyApi = sportyApi;
    this.pitsApi = pitsApi;
    this.jaredApi = jaredApi;
    this.date = moment()
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    this.frequency = "ALL";
    this.page = 0;
    this.rowsPerPage = 10;
    this.enabled = true;
    this.games = [];
    this.rules = [];
    this.rulesLoading = false;
    this.ruleClips = [];
    this.expectedClips = [];
    this.selectedClips = [];
    this.selectedQueries = [];
    this.selectedMissingPlays = [];
    reaction(() => this.rules, () => this.getClipsForRules());
    reaction(() => this.ruleClips, () => this.getExpectedClipsForRules());
    reaction(() => this.page, () => this.getRules());
    reaction(() => this.rowsPerPage, () => this.getRules());
  }

  setDate(date) {
    this.date = date;
  }

  setEnabled(enabled) {
    this.enabled = enabled;
  }

  setPage(page) {
    this.page = page;
  }

  setFrequency(frequency) {
    this.frequency = frequency;
  }

  setRowsPerPage(rowsPerPage) {
    this.rowsPerPage = rowsPerPage;
  }

  getRules() {
    this.rulesLoading = true;
    this.sportyApi
      .getRules(
        this.date,
        this.frequency,
        this.enabled,
        this.rowsPerPage,
        this.page
      )
      .then(d => {
        this.rules = d.data;
        this.totalRules = d.total;
        this.rulesLoading = false;
      });
  }

  getClipsForRules() {
    this.ruleClips = [];
    this.rules.forEach(r => {
      this.sportyApi.getClipsForRule(r.ruleId, this.date).then(d => {
        this.ruleClips.push({
          ruleId: r.ruleId,
          clips: d.data
        });
      });
    });
  }

  get clipsForRuleMap() {
    return _.chain(this.ruleClips)
      .groupBy(c => c.ruleId)
      .mapValues(value => (value.length ? value[0] : []))
      .value();
  }

  getExpectedClipsForRules() {
    this.expectedClips = [];
    this.rules.forEach(r => {
      this.findExpectedClips(r);
    });
  }

  get expectedClipsForRuleMap() {
    return _.chain(this.expectedClips)
      .groupBy(c => c.ruleId)
      .mapValues(value => {
        return value.length ? value[0] : [];
      })
      .value();
  }

  runExpectedPlayEndQueries(rule) {
    const playQuery = `GameDate = "${this.date}" AND (${rule.baseQuery})`;
    this.pitsApi.search(playQuery, 1000).then(d => {
      this.expectedClips.push({
        ruleId: rule.ruleId,
        ruleType: rule.frequency,
        expected: d.total,
        queries: [
          {
            query: playQuery,
            plays: d.total,
            playsInfo: mapPlaysInfo(d.data)
          }
        ]
      });
    });
  }

  runExpectedGameEndQueries(rule) {
    this.jaredApi.getGamesForDate(this.date).then(games => {
      games.forEach(g => {
        const gameEndQuery = `GamePk = ${g.gamePk} AND (${rule.baseQuery})`;
        this.pitsApi.search(gameEndQuery, 1000).then(d => {
          const existingRuleInfo = _.filter(
            this.expectedClips,
            c => c.ruleId === rule.ruleId
          );

          if (existingRuleInfo.length === 1 && existingRuleInfo[0].queries) {
            if (d.total) {
              existingRuleInfo[0].expected += 1;
            }
            existingRuleInfo[0].queries.push({
              query: gameEndQuery,
              plays: d.total
            });
          } else {
            this.expectedClips.push({
              ruleId: rule.ruleId,
              ruleType: rule.frequency,
              expected: d.total ? 1 : 0,
              queries: [
                {
                  query: gameEndQuery,
                  plays: d.total,
                  playsInfo: mapPlaysInfo(d.data)
                }
              ]
            });
          }
        });
      });
    });
  }

  runExpectedAllGamesEndQueries(rule) {
    const allGamesQuery = `GameDate = "${this.date}" AND (${rule.baseQuery})`;
    this.pitsApi.search(allGamesQuery, 1000).then(d => {
      this.expectedClips.push({
        ruleId: rule.ruleId,
        expected: d.total > 0 ? 1 : d.total,
        ruleType: rule.frequency,
        queries: [
          {
            query: allGamesQuery,
            plays: d.total,
            playsInfo: mapPlaysInfo(d.data)
          }
        ]
      });
    });
  }

  findExpectedClips(rule) {
    const freq = rule.frequency;
    switch (freq) {
      case "ALL_GAMES_END":
        this.runExpectedAllGamesEndQueries(rule);
        break;
      case "PLAY_END":
        this.runExpectedPlayEndQueries(rule);
        break;
      case "GAME_END":
        this.runExpectedGameEndQueries(rule);
        break;
      case "INNING_END":
      case "HALF_INNING_END":
        //todo implement logic for inning rules
        break;
      default:
        break;
    }

    return 0;
  }
}

decorate(RuleStore, {
  date: observable,
  enabled: observable,
  expectedClips: observable,
  frequency: observable,
  games: observable,
  setDate: action,
  setFrequency: action,
  clipsForRuleMap: computed,
  expectedClipsForRuleMap: computed,
  getClipsForRules: action,
  getExpectedClipsForRules: observable,
  getRules: action,
  page: observable,
  rules: observable,
  rowsPerPage: observable,
  ruleClips: observable,
  rulesLoading: observable,
  totalRules: observable,
  selectedClips: observable,
  selectedQueries: observable,
  setRowsPerPage: action,
  setPage: action
});

export default RuleStore;
