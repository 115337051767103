import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import React from "react";
import { CircularProgress } from "@material-ui/core";
const ConfirmDialog = props => {
  const { title, children, open, setOpen, onConfirm } = props;

  const [loading, setLoading] = React.useState(false);

  const handleOnConfirm = () => {
    setLoading(true);
    onConfirm()
      .then(() => {
        setLoading(false);
        setOpen(false);
      })
      .catch(() => {
        setLoading(false);
        setOpen(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          color="secondary"
        >
          No
        </Button>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => {
              handleOnConfirm();
            }}
            color="default"
          >
            Yes
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
