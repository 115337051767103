import axios from "axios";

const BASE_URL = process.env.REACT_APP_SPORTYBOOK_BASE_URL;

class SportyBookApi {
  getScheduleEvents(gamePk, limit = 10, page = 0) {
    return axios
      .get(
        `${BASE_URL}/api/v1/games/${gamePk}/events?type=SCHEDULE_EVENT&limit=${limit}&page=${page}`
      )
      .then(r => r.data);
  }

  getPlayEvents(gamePk, playId, spanId, eventType, limit = 10, page = 0) {
    let url = `${BASE_URL}/api/v1/games/${gamePk}/plays/${playId}/events?limit=${limit}&page=${page}`;
    if (spanId) {
      url = `${url}&spanId=${spanId}`;
    }
    if (eventType) {
      url = `${url}&type=${eventType}`;
    }
    return axios.get(url).then(r => r.data);
  }

  getPlayDocuments(gamePk, playId) {
    return axios
      .get(`${BASE_URL}/api/v1/games/${gamePk}/plays/${playId}/documents`)
      .then(r => r.data);
  }
}

SportyBookApi.BASE_URL = BASE_URL;

export default SportyBookApi;
