import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { inject, observer } from "mobx-react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";

const styles = theme => ({});

class ClipDetailsRow extends React.Component {
  render() {
    const { classes, clip } = this.props;

    return (
      <TableRow key={clip.clipId}>
        <TableCell>{clip.title}</TableCell>
        <TableCell>{clip.description}</TableCell>
        <TableCell>{clip.plays ? clip.plays.length : <React.Fragment />}</TableCell>
        <TableCell>{clip.createdAt}</TableCell>
        <TableCell>
          <Button className={classes.button} href={clip.uri} target="_blank">
            VIDEO
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

export default inject("rootStore")(
  withStyles(styles)(observer(ClipDetailsRow))
);
