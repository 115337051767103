import { inject, observer } from "mobx-react";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { getSportyUrl } from "../httpClients/sportyWebApi";
const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    alignContent: "space-between"
  },
  containerItem: {
    margin: "auto",
    padding: `0 ${theme.spacing.unit}px`
  }
});

const columns = [
  { id: "query", numeric: false, disablePadding: false, label: "Query" },
  { id: "plays", numeric: false, disablePadding: false, label: "Plays" },
  { id: "link", numeric: false, disablePadding: false, label: "" }
];

class RuleQueriesDetailsTable extends React.Component {
  render() {
    const { classes, ruleQueriesDetail } = this.props;

    return (
      <Table>
        {ruleQueriesDetail &&
        ruleQueriesDetail.queries &&
        ruleQueriesDetail.queries.length > 0 ? (
          <TableHead>
            <TableRow>
              {columns.map(column => {
                return (
                  <TableCell
                    key={column.id}
                    numeric={column.numeric}
                    padding={column.disablePadding ? "none" : "default"}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : (
          <React.Fragment />
        )}
        <TableBody>
          {ruleQueriesDetail.queries ? (
            ruleQueriesDetail.queries.map(q => (
              <TableRow key={q.query}>
                <TableCell>{q.query}</TableCell>
                <TableCell>{q.plays}</TableCell>
                <TableCell>
                  <Button
                    className={classes.button}
                    href={getSportyUrl(q.query)}
                    target="_blank"
                  >
                    SEARCH
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <React.Fragment />
          )}
        </TableBody>
      </Table>
    );
  }
}

export default inject("rootStore")(
  withStyles(styles)(observer(RuleQueriesDetailsTable))
);
