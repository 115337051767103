import React from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const CenteredLoading = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
`;

export default class Loading extends React.PureComponent {
  render() {
    return (
      <Wrapper>
        <CenteredLoading />
      </Wrapper>
    );
  }
}