import {action, decorate, observable} from "mobx";
import _ from "lodash";

const LIMIT = 10;

class PlayDiagnosticStore {
  constructor(jaredApi) {
    this.jaredApi = jaredApi;
    this.playCache = [];
    this.playsLoading = [];
  }

  fetchPlayInfo(gamePk, playId) {
    this.playsLoading.push(playId);
    this.jaredApi.fetchPlayInfo(gamePk, playId).then(r => {
      this.playCache.push(r);
      _.remove(this.playsLoading, playId);
    });
  }

  fetchPlays(playIds, gamePk) {
    const playsToFetch = _.slice(playIds, 0, LIMIT);
    playsToFetch.map(p => this.fetchPlayInfo(gamePk, p));
  }

  getPlays(playIds) {
    return _.filter(this.playCache, p => playIds.includes(p.playId));
  }

  arePlaysLoading(playIds) {
    const playsToFetch = _.slice(playIds, 0, LIMIT);
    return !_.isEmpty(_.filter(this.playsLoading, p => p.playId in playsToFetch));
  }
}

decorate(PlayDiagnosticStore, {
  arePlaysLoading: observable,
  playCache: observable,
  fetchPlayInfo: action,
  getPlays: action,
  playsLoading: observable
});

export default PlayDiagnosticStore;
