import React from "react";
import TextField from "@material-ui/core/TextField";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Loading from "./Loading";
import withAuth from "../containers/withAuth";
import { ALL_VALID_ROLES } from "../constants/authConstants";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: 150
  }
});

const Container = styled.div`
  display: flex;
  margin-top: 20px;
  padding: 0 50px;
`;

const VideoInputs = styled.div`
  flex: 1 1 auto;
  padding: 0 20px;
`;

const VideoDiv = styled.div`
  flex: 1 1 auto;
  padding: 0 20px;
`;

const Video = styled.video`
  flex: 1 1 auto;
  padding: 0 20px;
`;

const InputTextField = props => (
  <TextField
    fullWidth={true}
    InputProps={{ disableUnderline: false }}
    {...props}
  />
);

class CheckOffset extends React.Component {
  render() {
    const {
      rootStore: { checkOffsetStore }
    } = this.props;

    return (
      <Container>
        <VideoInputs>
          <InputTextField
            margin="dense"
            id="playId"
            label="Play Id"
            type="text"
            inputProps={{ maxLength: 36 }}
            onChange={e => {
              checkOffsetStore.setPlayId(e.target.value);
            }}
          />
          <br />
          <InputTextField
            margin="dense"
            id="gamePk"
            label="GamePK"
            type="text"
            inputProps={{ maxLength: 10 }}
            onChange={e => {
              checkOffsetStore.setGamePk(e.target.value);
            }}
            value={checkOffsetStore.gamePk}
          />
          <br />
          <Select
            id="diamond-asset-name"
            label="Diamond Asset Name"
            value={checkOffsetStore.diamondAssetId}
            onChange={e => {
              checkOffsetStore.setDiamondAssetNameFromId(e.target.value);
              checkOffsetStore.setDiamondAssetId(e.target.value);
              checkOffsetStore.setDiamondTimecodeOffsetFromId(e.target.value);
            }}
          >
            {checkOffsetStore.diamondAssets.map(asset => (
              <MenuItem value={asset.assetId}>{asset.assetName}</MenuItem>
            ))}
          </Select>
          <br />
          <InputTextField
            margin="dense"
            id="diamond-asset-id"
            label="Diamond Asset Id"
            type="text"
            inputProps={{ maxLength: 10 }}
            onChange={e => {
              checkOffsetStore.setDiamondAssetId(e.target.value);
            }}
            value={checkOffsetStore.diamondAssetId}
          />
          <br />
          <InputTextField
            margin="dense"
            id="override"
            label="Timecode Override"
            type="text"
            inputProps={{ maxLength: 10 }}
            onChange={e => {
              checkOffsetStore.setTimeCodeOffset(e.target.value);
            }}
            value={checkOffsetStore.timecodeOffset}
          />
          <br />
          <InputTextField
            margin="dense"
            id="startTime"
            label="Start Time"
            type="text"
            inputProps={{ maxLength: 36 }}
            onChange={e => {
              checkOffsetStore.setStartTime(e.target.value);
            }}
            value={checkOffsetStore.startTime}
          />
          <br />
          <InputTextField
            margin="dense"
            id="endTime"
            label="End Time"
            type="text"
            inputProps={{ maxLength: 36 }}
            onChange={e => {
              checkOffsetStore.setEndTime(e.target.value);
            }}
            value={checkOffsetStore.endTime}
          />
          <br />
          <Button
            size="small"
            color="primary"
            variant="raised"
            onClick={() => checkOffsetStore.submit()}
            disabled={checkOffsetStore.videoLoading}
          >
            Submit
          </Button>
        </VideoInputs>
        {checkOffsetStore.videoLoading ? (
          <VideoDiv>
            <Loading />
          </VideoDiv>
        ) : (
          <Video
            src={checkOffsetStore.videoUrl}
            width="768"
            height="432"
            type="video/mp4"
            controls
          />
        )}
      </Container>
    );
  }
}

export default withAuth(
  inject("rootStore")(withStyles(styles)(observer(CheckOffset))),
  ALL_VALID_ROLES
);
